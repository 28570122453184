var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as a1lib from "alt1";
import DialogReader from 'alt1/dialog';
import * as extrafuncs from './extrafuncs';
import * as settings from "./settings";
var SlayerData = /** @class */ (function () {
    function SlayerData() {
        this.selectYourOwn = false;
        this.ticket = false;
        this.reaper = false;
        this.count = [];
        this.task = [];
    }
    return SlayerData;
}());
export { SlayerData };
var SlayerDialogReader = /** @class */ (function () {
    function SlayerDialogReader() {
    }
    SlayerDialogReader.prototype.read = function () {
        return __awaiter(this, arguments, void 0, function (buffer) {
            var pos, _a, r, g, b, box, msg, countText, e, countText, e, i, msg, countText;
            if (buffer === void 0) { buffer = null; }
            return __generator(this, function (_b) {
                if (!buffer) {
                    buffer = a1lib.captureHoldFullRs();
                }
                if (!this.dialogReader) {
                    this.dialogReader = new DialogReader();
                }
                this.data = new SlayerData();
                pos = this.dialogReader.find(buffer);
                if (!pos)
                    return [2 /*return*/, null];
                // Overlay a rectangle around the matched area on screen if we're running in alt1 and setting is enabled
                if (window.alt1 && alt1.permissionOverlay && settings.controller.ui.overlay) {
                    _a = extrafuncs.hexToRgb(settings.controller.ui.overlayColor), r = _a[0], g = _a[1], b = _a[2];
                    alt1.overLayRect(a1lib.mixColor(r, g, b), pos.x, pos.y, pos.width, pos.height, settings.controller.ui.overlayTimer, 3);
                }
                box = this.dialogReader.read(buffer);
                if (!box)
                    return [2 /*return*/, null];
                if (box.text && (!box.opts || box.opts.length !== 2)) {
                    msg = box.text.toString();
                    // Parsing logic for various types of dialogs
                    if (msg.toLowerCase().includes("select your own task")) {
                        this.data.selectYourOwn = true;
                    }
                    if (msg.toLowerCase().includes("require you to collect")) {
                        this.data.reaper = true;
                        countText = msg.substring(msg.indexOf("collect ") + 8, msg.indexOf(" souls"));
                        this.data.count[0] = parseInt(countText);
                        this.data.task[0] = msg.substring(msg.indexOf("battle: ") + 8, msg.indexOf(". Can"));
                    }
                    if (msg.toLowerCase().includes("new task is to kill")) {
                        e = msg.substring(msg.indexOf("kill ") + 5, msg.length - 1);
                        countText = e.substring(0, e.includes(",") ? e.indexOf(",") : e.indexOf(" "));
                        this.data.count[0] = parseInt(countText);
                        this.data.task[0] = e.substring((e.includes(",") ? e.indexOf(",") : e.indexOf(" ")) + 1);
                    }
                    if (msg.toLowerCase().includes("still need to kill")) {
                        e = msg.substring(msg.indexOf("kill ") + 5);
                        this.data.count[0] = parseInt(e.substring(0, e.indexOf(" ")));
                        this.data.task[0] = e.substring(e.indexOf(" ") + 1, e.includes(".") ? e.indexOf(".") : e.length - 1);
                    }
                    if (msg.toLowerCase().includes("still hunting")) {
                        this.data.task[0] = msg.substring(msg.indexOf("hunting ") + 8, msg.indexOf(";"));
                    }
                }
                if (box.opts && box.opts.length === 2 && (box.title && box.title.toLowerCase().includes("change your assigned slayer task"))) {
                    this.data.ticket = true;
                    for (i = 0; i < box.opts.length; i++) {
                        msg = box.opts[i].text;
                        countText = msg.substring(0, msg.indexOf(' '));
                        this.data.count[i] = parseInt(countText);
                        this.data.task[i] = msg.substring(msg.indexOf(' ') + 1, msg.includes('(') ? msg.indexOf('(') - 1 : msg.length);
                    }
                }
                return [2 /*return*/, this.data];
            });
        });
    };
    return SlayerDialogReader;
}());
export { SlayerDialogReader };
