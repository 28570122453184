var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as dochandler from '../dochandler';
import * as reapers from '../data/reapers';
import * as assignments from '../data/assignnments';
import * as settings from '../settings';
import * as slayer from '../slayer';
import * as logger from "../logger";
// Define variables
export var doubleLoaded = false;
export var singleLoaded = false;
export var reaperLoaded = false;
export var suggestedLoaded = false;
export var limitedLoaded = false;
export var get;
(function (get) {
    function elc(id, base) {
        return base.getElementsByClassName(id)[0];
    }
    get.elc = elc;
    function elid(id) {
        return document.getElementById(id);
    }
    get.elid = elid;
    function searchInput() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, document.querySelector("input[name='search-input']").value];
            });
        });
    }
    get.searchInput = searchInput;
})(get || (get = {}));
export var reset;
(function (reset) {
    function single() {
        return __awaiter(this, void 0, void 0, function () {
            var baseElement;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!singleLoaded)
                            return [2 /*return*/];
                        return [4 /*yield*/, get.elid("base")];
                    case 1:
                        baseElement = _a.sent();
                        baseElement.innerHTML = "";
                        singleLoaded = false;
                        return [2 /*return*/];
                }
            });
        });
    }
    reset.single = single;
    function double() {
        return __awaiter(this, void 0, void 0, function () {
            var baseElement;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!doubleLoaded)
                            return [2 /*return*/];
                        return [4 /*yield*/, get.elid("base")];
                    case 1:
                        baseElement = _a.sent();
                        baseElement.innerHTML = "";
                        doubleLoaded = false;
                        return [2 /*return*/];
                }
            });
        });
    }
    reset.double = double;
    function reaper() {
        return __awaiter(this, void 0, void 0, function () {
            var baseElement;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!reaperLoaded)
                            return [2 /*return*/];
                        return [4 /*yield*/, get.elid("base")];
                    case 1:
                        baseElement = _a.sent();
                        baseElement.innerHTML = "";
                        reaperLoaded = false;
                        return [2 /*return*/];
                }
            });
        });
    }
    reset.reaper = reaper;
    function suggested() {
        return __awaiter(this, void 0, void 0, function () {
            var baseElement;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!suggestedLoaded)
                            return [2 /*return*/];
                        return [4 /*yield*/, get.elid("base")];
                    case 1:
                        baseElement = _a.sent();
                        baseElement.innerHTML = "";
                        suggestedLoaded = false;
                        return [2 /*return*/];
                }
            });
        });
    }
    reset.suggested = suggested;
    function searchBox() {
        return __awaiter(this, void 0, void 0, function () {
            var searchInfo;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, get.elid("search-input")];
                    case 1:
                        searchInfo = _a.sent();
                        searchInfo.value = "";
                        toggle.searchText();
                        return [2 /*return*/];
                }
            });
        });
    }
    reset.searchBox = searchBox;
})(reset || (reset = {}));
export var set;
(function (set) {
    // Set to use a double document
    function double(task0_1, task1_1) {
        return __awaiter(this, arguments, void 0, function (task0, task1, bypass) {
            if (bypass === void 0) { bypass = false; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if ((task0 == null || task1 == null) && !doubleLoaded)
                            return [2 /*return*/];
                        logger.log("Load Double: [".concat(task0.count, "] ").concat(task0.name, " | [").concat(task1.count, "] ").concat(task1.name));
                        // Reset all others
                        return [4 /*yield*/, reset.single()];
                    case 1:
                        // Reset all others
                        _a.sent();
                        return [4 /*yield*/, reset.reaper()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, reset.suggested()];
                    case 3:
                        _a.sent();
                        if (!!doubleLoaded) return [3 /*break*/, 5];
                        return [4 /*yield*/, setup.doubleAssignment()];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [4 /*yield*/, dochandler.change.assignment(0, task0.name, task0.count)];
                    case 6:
                        _a.sent();
                        return [4 /*yield*/, dochandler.change.assignment(1, task1.name, task1.count)];
                    case 7:
                        _a.sent();
                        // Log history if not bypassed
                        if (!bypass && task0.name && task1.name) {
                            settings.change.addHistory({ type: 'double', tasks: [task0, task1] });
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    set.double = double;
    // Set to use a single document
    function single(task_1) {
        return __awaiter(this, arguments, void 0, function (task, bypass) {
            if (bypass === void 0) { bypass = false; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (task == null && !singleLoaded)
                            return [2 /*return*/];
                        logger.log("Load Single: [".concat(task.count, "] ").concat(task.name));
                        // Reset all others
                        return [4 /*yield*/, reset.double()];
                    case 1:
                        // Reset all others
                        _a.sent();
                        return [4 /*yield*/, reset.reaper()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, reset.suggested()];
                    case 3:
                        _a.sent();
                        if (!!singleLoaded) return [3 /*break*/, 5];
                        return [4 /*yield*/, setup.singleAssignment(0)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [4 /*yield*/, dochandler.change.assignment(0, task.name, task.count)];
                    case 6:
                        _a.sent();
                        // Log history if not bypassed
                        if (!bypass && task.name) {
                            settings.change.addHistory({ type: 'single', tasks: [task] });
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    set.single = single;
    // Set to use reaper tasks doc
    function reaper(task_1) {
        return __awaiter(this, arguments, void 0, function (task, bypass) {
            if (bypass === void 0) { bypass = false; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (task == null && !reaperLoaded)
                            return [2 /*return*/];
                        logger.log("Load Reaper: [".concat(task.count, "] ").concat(task.name));
                        // Reset all others
                        return [4 /*yield*/, reset.single()];
                    case 1:
                        // Reset all others
                        _a.sent();
                        return [4 /*yield*/, reset.double()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, reset.suggested()];
                    case 3:
                        _a.sent();
                        if (!!reaperLoaded) return [3 /*break*/, 5];
                        return [4 /*yield*/, setup.reaperAssignment()];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [4 /*yield*/, dochandler.change.reaper(task.name, task.count)];
                    case 6:
                        _a.sent();
                        // Log history if not bypassed
                        if (!bypass && task.name) {
                            settings.change.addHistory({ type: 'reaper', tasks: [task] });
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    set.reaper = reaper;
    // Set to use suggested tasks doc
    function suggested() {
        return __awaiter(this, arguments, void 0, function (bypass) {
            if (bypass === void 0) { bypass = false; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (suggestedLoaded)
                            return [2 /*return*/];
                        logger.log("Load Suggested!");
                        // Reset all others
                        return [4 /*yield*/, reset.single()];
                    case 1:
                        // Reset all others
                        _a.sent();
                        return [4 /*yield*/, reset.double()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, reset.reaper()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, dochandler.main.setup.suggested()];
                    case 4:
                        _a.sent();
                        // Log history if not bypassed
                        if (!bypass) {
                            settings.change.addHistory({ type: 'suggestion', tasks: null });
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    set.suggested = suggested;
    // Set search button
    function searchButtonText(s) {
        dochandler.main.get.elid("search-button").innerHTML = "".concat(s, "<span style=\"visibility: hidden;\">. . .</span>");
    }
    set.searchButtonText = searchButtonText;
    // Set the search input
    function searchInput(s) {
        if (s == null)
            return;
        dochandler.main.get.elid("search-input").value = s;
        dochandler.main.toggle.searchText();
    }
    set.searchInput = searchInput;
})(set || (set = {}));
export var setup;
(function (setup) {
    var suggestions = [];
    function getSuggestions() {
        return __awaiter(this, void 0, void 0, function () {
            var reapersIndexList, assignmentsIndexList;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, reapers.getIndexList()];
                    case 1:
                        reapersIndexList = _a.sent();
                        return [4 /*yield*/, assignments.getIndexList()];
                    case 2:
                        assignmentsIndexList = _a.sent();
                        return [2 /*return*/, reapersIndexList.concat(assignmentsIndexList)];
                }
            });
        });
    }
    setup.getSuggestions = getSuggestions;
    // Function to filter options based on input
    function filterOptions() {
        return __awaiter(this, void 0, void 0, function () {
            var searchSuggestions, query, filteredOptions;
            return __generator(this, function (_a) {
                searchSuggestions = document.querySelector("#search-suggestions");
                query = document.querySelector("#search-input").value.toLowerCase();
                if (query.includes("|"))
                    query = query.substring(query.indexOf("|") + 1).trim();
                searchSuggestions.innerHTML = ""; // Clear previous suggestions
                // Check if `suggestions` has been populated
                if (suggestions.length === 0) {
                    console.warn("Suggestions are not yet loaded.");
                    return [2 /*return*/];
                }
                filteredOptions = suggestions.filter(function (option) {
                    return option.toLowerCase().includes(query);
                });
                // Populate the select dropdown with matching options
                filteredOptions.forEach(function (option) {
                    var optionElement = document.createElement("option");
                    optionElement.value = option;
                    optionElement.textContent = option;
                    searchSuggestions.appendChild(optionElement);
                });
                // Show or hide the dropdown based on the presence of matching options
                searchSuggestions.style.display = filteredOptions.length > 0 ? "block" : "none";
                // Adjust size of dropdown based on the length of options available
                searchSuggestions.size = filteredOptions.length > 1 && filteredOptions.length !== 0 ? filteredOptions.length : 2;
                return [2 /*return*/];
            });
        });
    }
    function setupSearchListeners() {
        return __awaiter(this, void 0, void 0, function () {
            var searchInput, searchButton, searchSuggestions;
            return __generator(this, function (_a) {
                searchInput = document.querySelector("#search-input");
                searchButton = document.querySelector("#search-button");
                searchSuggestions = document.querySelector("#search-suggestions");
                // Setup search button listener
                searchButton.addEventListener('click', function () { slayer.searchTasks(); });
                // Show suggestions based on input value
                searchInput.addEventListener("input", function () {
                    dochandler.main.toggle.searchText();
                    filterOptions();
                });
                // Hide suggestions on blur
                searchInput.addEventListener("blur", function (e) {
                    if (e.relatedTarget !== searchSuggestions) {
                        searchSuggestions.style.display = "none";
                    }
                });
                // Show suggestions on click and focus
                searchInput.addEventListener("click", filterOptions);
                searchInput.addEventListener("focus", filterOptions);
                // Enable keyboard navigation
                searchInput.addEventListener("keydown", function (e) {
                    // Force click on enter
                    if (e.key === "Enter") {
                        searchSuggestions.style.display = "none";
                        searchButton.click();
                    }
                    // Force blur on esc
                    if (e.key === "Escape") {
                        searchInput.blur();
                    }
                    // Focus on select when arrow key down
                    if (e.key === "ArrowDown") {
                        searchSuggestions.focus();
                        searchSuggestions.selectedIndex = 0;
                    }
                    // Tab key fills input with the first suggestion
                    if (e.key === "Tab") {
                        if (searchInput.value.length == 0)
                            return;
                        e.preventDefault(); // Prevent default tab behavior
                        if (searchSuggestions.options.length > 0) {
                            if (searchInput.value.includes("|"))
                                searchInput.value = searchInput.value.substring(0, searchInput.value.indexOf("|") + 1) + " " + searchSuggestions.options[0].value;
                            else
                                searchInput.value = searchSuggestions.options[0].value;
                            searchSuggestions.style.display = "none"; // Optionally, hide the suggestions dropdown
                        }
                    }
                });
                // Update input value when an option is selected + click button
                searchSuggestions.addEventListener("click", function () {
                    if (searchSuggestions.style.display === "none")
                        return;
                    if (searchInput.value.includes("|"))
                        searchInput.value = searchInput.value.substring(0, searchInput.value.indexOf("|") + 1) + " " + searchSuggestions.value;
                    else
                        searchInput.value = searchSuggestions.value;
                    searchSuggestions.style.display = "none"; // Hide the dropdown
                    searchButton.click();
                });
                searchSuggestions.addEventListener("keydown", function (e) {
                    if (searchSuggestions.style.display === "none")
                        return;
                    // Set the input value to the selected option
                    if (e.key === "Enter") {
                        searchInput.value = searchSuggestions.options[searchSuggestions.selectedIndex].value;
                        searchSuggestions.style.display = "none";
                        searchButton.click();
                    }
                    // Go back to input when going up on the first item
                    if (e.key === "ArrowUp" && searchSuggestions.selectedIndex === 0) {
                        searchInput.focus();
                    }
                });
                // Remove suggestions when not focussing on them
                searchSuggestions.addEventListener("blur", function (e) {
                    if (e.relatedTarget !== searchInput) {
                        searchSuggestions.style.display = "none";
                    }
                });
                return [2 /*return*/];
            });
        });
    }
    // Setup the main document
    function documentMain() {
        return __awaiter(this, void 0, void 0, function () {
            var nisElement, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        nisElement = dochandler.main.get.elid("nis");
                        _a = nisElement;
                        return [4 /*yield*/, fetch("./pages/doc/body.html?cache_bust=".concat(Date.now())).then(function (r) { return r.text(); })];
                    case 1:
                        _a.innerHTML = _b.sent();
                        return [4 /*yield*/, getSuggestions()];
                    case 2:
                        suggestions = _b.sent();
                        // Setup event listeners
                        dochandler.main.get.elid("infobutton").addEventListener('click', dochandler.open.aboutInfo);
                        dochandler.main.get.elid("menubutton").addEventListener('click', dochandler.open.settingsPage);
                        dochandler.main.get.elid("donatebutton").addEventListener('click', dochandler.open.donate);
                        dochandler.main.get.elid("backbutton").addEventListener('click', settings.change.goPreviousHistory);
                        dochandler.main.get.elid("forwardbutton").addEventListener('click', settings.change.goNextHistory);
                        // Setup Search Listeners
                        setupSearchListeners();
                        // Setup warning link listener
                        dochandler.main.get.elid("warning-link").addEventListener('click', function (e) {
                            dochandler.main.get.elid("warning-ui").style.visibility = "hidden";
                        });
                        // Setup warnings based on the status
                        if (window.alt1) {
                            if (!alt1.permissionInstalled)
                                dochandler.main.get.elid("warning-msg").innerHTML = "You should click <a href=\"https://slayer.unlishema.org\">Add App</a> at top right";
                            else if (!alt1.permissionOverlay || !alt1.permissionGameState || !alt1.permissionPixel)
                                dochandler.main.get.elid("warning-msg").innerHTML = "This app is missing permissions";
                            else if (!alt1.rsLinked)
                                dochandler.main.get.elid("warning-msg").innerHTML = "RuneScape not detected, <a onclick=\"window.open('https://discord.gg/G3SbcS8');\">troubleshoot</a>";
                            else
                                dochandler.main.get.elid("warning-ui").style.visibility = "hidden";
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    setup.documentMain = documentMain;
    // Setup the base for double assignment
    function doubleAssignment() {
        return __awaiter(this, void 0, void 0, function () {
            var baseElement, _a, _loop_1, i;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        limitedLoaded = true;
                        baseElement = dochandler.main.get.elid("base");
                        _a = baseElement;
                        return [4 /*yield*/, fetch("./pages/doc/dual.html?cache_bust=".concat(Date.now())).then(function (r) { return r.text(); })];
                    case 1:
                        _a.innerHTML = _b.sent();
                        _loop_1 = function (i) {
                            var side, _c, extraInfo, _d;
                            return __generator(this, function (_e) {
                                switch (_e.label) {
                                    case 0:
                                        side = dochandler.getSide(i);
                                        _c = side;
                                        return [4 /*yield*/, fetch("./pages/doc/example.html?cache_bust=".concat(Date.now())).then(function (r) { return r.text(); })];
                                    case 1:
                                        _c.innerHTML = _e.sent();
                                        // Setup event listeners
                                        dochandler.main.get.elc("task-wiki", side).addEventListener('click', function () { return dochandler.open.wiki.assignment(i); });
                                        dochandler.main.get.elc("task-wiki", side).addEventListener('keydown', function (e) {
                                            if (e.key === "Enter")
                                                dochandler.main.get.elc("task-wiki", side).click();
                                        });
                                        dochandler.main.get.elc("task-wiki", side).addEventListener('mouseover', function () { return dochandler.hover.assignmentWiki(i); });
                                        if (!!settings.controller.ui.limited) return [3 /*break*/, 3];
                                        extraInfo = dochandler.main.get.elc("extra-info", side);
                                        _d = extraInfo;
                                        return [4 /*yield*/, fetch("./pages/doc/example-extra.html?cache_bust=".concat(Date.now())).then(function (r) { return r.text(); })];
                                    case 2:
                                        _d.innerHTML = _e.sent();
                                        limitedLoaded = false;
                                        dochandler.main.get.elc("preview-task-wiki", side).addEventListener('mouseleave', function () { return dochandler.hide.assignmentWikiPreview(i); });
                                        dochandler.main.get.elc("preview-creature-wiki", side).addEventListener('mouseleave', function () { return dochandler.hide.creatureWikiPreview(i); });
                                        dochandler.main.get.elc("equipMore", side).addEventListener('mouseenter', function () { return dochandler.hover.moreEquip(i); });
                                        dochandler.main.get.elc("equipHidden", side).addEventListener('mouseleave', function () { return dochandler.hide.moreEquip(i); });
                                        dochandler.main.get.elc("locationMore", side).addEventListener('mouseenter', function () { return dochandler.hover.moreLocations(i); });
                                        dochandler.main.get.elc("locationHidden", side).addEventListener('mouseleave', function () { return dochandler.hide.moreLocations(i); });
                                        dochandler.main.get.elc("creature-wiki", side).addEventListener('click', function () { return dochandler.open.wiki.creature(i); });
                                        dochandler.main.get.elc("creature-wiki", side).addEventListener('keydown', function (e) {
                                            if (e.key === "Enter")
                                                dochandler.main.get.elc("creature-wiki", side).click();
                                        });
                                        dochandler.main.get.elc("creature-wiki", side).addEventListener('mouseover', function () { return dochandler.hover.creatureWiki(i); });
                                        dochandler.main.get.elc("creatures", side).addEventListener('change', function () { return dochandler.change.creature(i); });
                                        dochandler.main.get.elc("variants", side).addEventListener('change', function () { return dochandler.change.variant(i); });
                                        _e.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        };
                        i = 0;
                        _b.label = 2;
                    case 2:
                        if (!(i < 2)) return [3 /*break*/, 5];
                        return [5 /*yield**/, _loop_1(i)];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4:
                        i++;
                        return [3 /*break*/, 2];
                    case 5:
                        // Adjust the doc for limited and full
                        adjustForSettings();
                        doubleLoaded = true;
                        singleLoaded = false;
                        return [2 /*return*/];
                }
            });
        });
    }
    setup.doubleAssignment = doubleAssignment;
    // Setup the base for single assignment
    function singleAssignment(i) {
        return __awaiter(this, void 0, void 0, function () {
            var baseElement, _a, side, _b, extraInfo, _c, statusElement;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        limitedLoaded = true;
                        baseElement = dochandler.main.get.elid("base");
                        _a = baseElement;
                        return [4 /*yield*/, fetch("./pages/doc/single.html?cache_bust=".concat(Date.now())).then(function (r) { return r.text(); })];
                    case 1:
                        _a.innerHTML = _d.sent();
                        side = dochandler.getSide(i);
                        _b = side;
                        return [4 /*yield*/, fetch("./pages/doc/example.html?cache_bust=".concat(Date.now())).then(function (r) { return r.text(); })];
                    case 2:
                        _b.innerHTML = _d.sent();
                        // Setup event listeners
                        dochandler.main.get.elc("task-wiki", side).addEventListener('click', function () { return dochandler.open.wiki.assignment(i); });
                        dochandler.main.get.elc("task-wiki", side).addEventListener('mouseover', function () { return dochandler.hover.assignmentWiki(i); });
                        if (!!settings.controller.ui.limited) return [3 /*break*/, 4];
                        extraInfo = dochandler.main.get.elc("extra-info", side);
                        _c = extraInfo;
                        return [4 /*yield*/, fetch("./pages/doc/example-extra.html?cache_bust=".concat(Date.now())).then(function (r) { return r.text(); })];
                    case 3:
                        _c.innerHTML = _d.sent();
                        limitedLoaded = false;
                        dochandler.main.get.elc("preview-task-wiki", side).addEventListener('mouseleave', function () { return dochandler.hide.assignmentWikiPreview(i); });
                        dochandler.main.get.elc("preview-creature-wiki", side).addEventListener('mouseleave', function () { return dochandler.hide.creatureWikiPreview(i); });
                        dochandler.main.get.elc("equipMore", side).addEventListener('mouseenter', function () { return dochandler.hover.moreEquip(i); });
                        dochandler.main.get.elc("equipHidden", side).addEventListener('mouseleave', function () { return dochandler.hide.moreEquip(i); });
                        dochandler.main.get.elc("locationMore", side).addEventListener('mouseenter', function () { return dochandler.hover.moreLocations(i); });
                        dochandler.main.get.elc("locationHidden", side).addEventListener('mouseleave', function () { return dochandler.hide.moreLocations(i); });
                        dochandler.main.get.elc("creature-wiki", side).addEventListener('click', function () { return dochandler.open.wiki.creature(i); });
                        dochandler.main.get.elc("creature-wiki", side).addEventListener('mouseover', function () { return dochandler.hover.creatureWiki(i); });
                        dochandler.main.get.elc("creatures", side).addEventListener('change', function () { return dochandler.change.creature(i); });
                        dochandler.main.get.elc("variants", side).addEventListener('change', function () { return dochandler.change.variant(i); });
                        _d.label = 4;
                    case 4:
                        statusElement = dochandler.main.get.elc("status", side);
                        if (settings.controller.ui.status) {
                            statusElement.style.visibility = "visible";
                        }
                        else {
                            statusElement.style.visibility = "hidden";
                        }
                        // Adjust the doc for limited and full
                        adjustForSettings();
                        singleLoaded = true;
                        return [2 /*return*/];
                }
            });
        });
    }
    setup.singleAssignment = singleAssignment;
    // Setup the base for reaper assignment
    function reaperAssignment() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, singleAssignment(2)];
                    case 1:
                        _a.sent();
                        reaperLoaded = true;
                        singleLoaded = false;
                        return [2 /*return*/];
                }
            });
        });
    }
    setup.reaperAssignment = reaperAssignment;
    // Setup the base for suggested
    function suggested() {
        return __awaiter(this, void 0, void 0, function () {
            var baseElement, _a, suggestType, _loop_2, i;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        baseElement = dochandler.main.get.elid("base");
                        _a = baseElement;
                        return [4 /*yield*/, fetch("./pages/doc/suggested.html?cache_bust=".concat(Date.now())).then(function (r) { return r.text(); })];
                    case 1:
                        _a.innerHTML = _b.sent();
                        suggestType = dochandler.main.get.elid("type-suggest");
                        if (suggestType) {
                            suggestType.addEventListener('click', function () { return window.open("https://runescape.wiki/w/Slayer_training/High-levelled"); });
                        }
                        _loop_2 = function (i) {
                            var element = dochandler.main.get.elid("suggestion-".concat(i));
                            if (element) {
                                element.addEventListener('click', function () { return slayer.searchTasks(element.innerHTML); });
                            }
                        };
                        for (i = 1; i <= 12; i++) {
                            _loop_2(i);
                        }
                        suggestedLoaded = true;
                        return [2 /*return*/];
                }
            });
        });
    }
    setup.suggested = suggested;
    // If limited, set the size of the base
    function adjustForSettings() {
        var baseElement = dochandler.main.get.elid("base");
        var divideElement = dochandler.main.get.elid("divide");
        var copyrightElement = dochandler.main.get.elid("copyright");
        if (settings.controller.ui.limited) {
            baseElement.classList.remove("base-full");
            if (divideElement) {
                divideElement.classList.remove("divide-full");
            }
            copyrightElement.classList.remove("copyright-full");
        }
        else {
            baseElement.classList.add("base-full");
            if (divideElement) {
                divideElement.classList.add("divide-full");
            }
            copyrightElement.classList.add("copyright-full");
        }
    }
})(setup || (setup = {}));
export var toggle;
(function (toggle) {
    // Toggle if we are capturing screen or searching text
    function searchText() {
        return __awaiter(this, void 0, void 0, function () {
            var searchResult, autoCaptureEnabled, searchInput;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        searchResult = slayer.searchResult;
                        autoCaptureEnabled = settings.controller.autoCapture.enabled;
                        return [4 /*yield*/, dochandler.main.get.searchInput()];
                    case 1:
                        searchInput = _a.sent();
                        if ((searchResult && searchInput === "") || (!autoCaptureEnabled && searchInput === "")) {
                            dochandler.main.set.searchButtonText("Capture");
                        }
                        else {
                            dochandler.main.set.searchButtonText("Search");
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    toggle.searchText = searchText;
})(toggle || (toggle = {}));
