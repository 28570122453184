import * as dochandler from "../dochandler";
import * as settings from "../settings";
export function assignmentWiki(i) {
    if (settings.controller.ui.limited)
        return;
    var side = dochandler.getSide(i);
    var showTimer;
    var hideTimer = null;
    showTimer = setTimeout(function () { return dochandler.show.assignmentWikiPreview(i); }, 500);
    dochandler.main.get.elc("task-wiki", side).onmouseleave = function () {
        hideTimer = setTimeout(function () { return dochandler.hide.assignmentWikiPreview(i); }, 200);
        clearTimeout(showTimer);
    };
    dochandler.main.get.elc("preview-task-wiki", side).onmouseenter = function () {
        if (hideTimer !== null)
            clearTimeout(hideTimer);
    };
}
export function creatureWiki(i) {
    if (settings.controller.ui.limited)
        return;
    var side = dochandler.getSide(i);
    var showTimer;
    var hideTimer = null;
    showTimer = setTimeout(function () { return dochandler.show.creatureWikiPreview(i); }, 500);
    dochandler.main.get.elc("creature-wiki", side).onmouseleave = function () {
        hideTimer = setTimeout(function () { return dochandler.hide.creatureWikiPreview(i); }, 200);
        clearTimeout(showTimer);
    };
    dochandler.main.get.elc("preview-creature-wiki", side).onmouseenter = function () {
        if (hideTimer !== null)
            clearTimeout(hideTimer);
    };
}
export function moreEquip(i) {
    if (settings.controller.ui.limited)
        return;
    var side = dochandler.getSide(i);
    var showTimer = setTimeout(function () { return dochandler.show.moreEquip(i); }, 500);
    dochandler.main.get.elc("equipMore", side).onmouseleave = function () {
        clearTimeout(showTimer);
    };
}
export function moreLocations(i) {
    if (settings.controller.ui.limited)
        return;
    var side = dochandler.getSide(i);
    var showTimer = setTimeout(function () { return dochandler.show.moreLocations(i); }, 500);
    dochandler.main.get.elc("locationMore", side).onmouseleave = function () {
        clearTimeout(showTimer);
    };
}
