import * as dochandler from '../dochandler';
import * as settings from '../settings';
import * as logger from "../logger";
// Open the Donate Page (https://www.paypal.com/donate/?hosted_button_id=LRFC6766YMVTQ)
export function donate() {
    window.open("https://www.paypal.com/donate/?hosted_button_id=LRFC6766YMVTQ");
}
// Open the about info page
export function aboutInfo() {
    var width = 680;
    var height = 550;
    var left = window.screenX + (window.innerWidth / 2) - (width / 2);
    var top = window.screenY + (window.innerHeight / 2) - (height / 2);
    window.open("./pages/about.html?cache_bust=".concat(Date.now()), null, "width=".concat(width, ",height=").concat(height, ",left=").concat(left, ",top=").concat(top));
}
// Open the settings page
export function settingsPage() {
    var width = 650;
    var height = 600;
    var left = window.screenX + (window.innerWidth / 2) - (width / 2);
    var top = window.screenY + (window.innerHeight / 2) - (height / 2);
    var settingsWindow = window.open("./pages/settings.html?cache_bust=".concat(Date.now()), "_blank", "width=".concat(width, ",height=").concat(height, ",left=").concat(left, ",top=").concat(top));
    logger.warn("Settings Opened!");
    settingsWindow === null || settingsWindow === void 0 ? void 0 : settingsWindow.window.console.warn("Settings Loaded!");
    settingsWindow === null || settingsWindow === void 0 ? void 0 : settingsWindow.addEventListener('keyup', function (e) {
        if ((e.which || e.keyCode) === 116) {
            // Prevent F5 key press from refreshing the page
            e.preventDefault();
        }
    });
    settingsWindow === null || settingsWindow === void 0 ? void 0 : settingsWindow.addEventListener('beforeunload', function () {
        // Load the new settings and apply them
        settings.controller.load();
        dochandler.main.toggle.searchText();
        logger.warn("Settings Closed!");
        settingsWindow === null || settingsWindow === void 0 ? void 0 : settingsWindow.window.console.warn("Settings Saved!");
    });
}
export var wiki;
(function (wiki) {
    // Opens the wiki page for a specific task
    function assignment(i) {
        var side = dochandler.getSide(i);
        var task = dochandler.main.get.elc("name", side).innerHTML;
        if (task)
            window.open("https://runescape.wiki/".concat(task, "_(Slayer_assignment)"), "_blank");
    }
    wiki.assignment = assignment;
    // Opens the wiki page for a specific creature
    function creature(i) {
        var side = dochandler.getSide(i);
        var creature = dochandler.main.get.elc("creatures", side).value;
        var variant = dochandler.main.get.elc("variants", side).value;
        if (creature && variant)
            window.open("https://runescape.wiki/".concat(creature, "#").concat(variant), "_blank");
        else if (creature)
            window.open("https://runescape.wiki/".concat(creature), "_blank");
    }
    wiki.creature = creature;
    // Opens the wiki page for a specific master
    function master(i, j) {
        var side = dochandler.getSide(i);
        var master = dochandler.main.get.elc("masterImage" + j, side).alt;
        if (master)
            window.open("https://runescape.wiki/".concat(master), "_blank");
    }
    wiki.master = master;
})(wiki || (wiki = {}));
