import * as dochandler from "../dochandler";
import * as settings from "../settings";
export function assignmentWikiPreview(i) {
    if (settings.controller.ui.limited)
        return;
    var side = dochandler.getSide(i);
    dochandler.main.get.elc("preview-task-wiki", side).style.visibility = "hidden";
}
export function creatureWikiPreview(i) {
    if (settings.controller.ui.limited)
        return;
    var side = dochandler.getSide(i);
    dochandler.main.get.elc("preview-creature-wiki", side).style.visibility = "hidden";
}
export function moreEquip(i) {
    if (settings.controller.ui.limited)
        return;
    var side = dochandler.getSide(i);
    dochandler.main.get.elc("equipHidden", side).style.visibility = "hidden";
}
export function moreLocations(i) {
    if (settings.controller.ui.limited)
        return;
    var side = dochandler.getSide(i);
    dochandler.main.get.elc("locationHidden", side).style.visibility = "hidden";
}
