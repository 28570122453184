var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as dochandler from "./dochandler";
import * as slayer from "./slayer";
import * as main from "./dochandler/main";
import * as logger from "./logger";
// Controls all the settings data
var SettingsDataController = /** @class */ (function () {
    function SettingsDataController() {
        this.loaded = false;
        this.version = 1;
        this.searchAlgorithim = "default";
        this.autoCapture = {
            enabled: true,
            counter: true,
            search: true,
            loopTimer: 500,
            counterTimer: 500,
            searchTimer: 10000,
            dialogTimer: 5000,
        };
        this.ui = {
            style: "default",
            styleOverride: true,
            limited: false,
            status: false,
            overlay: false,
            overlayColor: "#ff8c00",
            overlayTimer: 500,
            historyRetentionCount: 10,
            hideAlt1Warn: false,
        };
        this.hiddenCreatures = {
            default: true,
            dragonkin: true,
            godWars: true,
            ghorrock: true,
            heartGielinor: true,
            newVarrock: true,
            shadowReef: true,
            strongholdSecurity: true,
            templeTrekking: true,
            unchartedIsles: true,
            elite: true,
            monster: false,
            summoned: true
        };
        this.default = {
            taskName0: "waterfiends",
            taskName1: "black demons",
            taskCount0: 777,
            taskCount1: 666
        };
    }
    SettingsDataController.prototype.createTestInstance = function () {
        // Store this app's instance locally so we always keep the same settings
        var currentInstance = 0;
        // On app load add the instance
        document.addEventListener("DOMContentLoaded", function () {
            var instanceSet = JSON.parse(localStorage.getItem("instance-set") || "[]");
            currentInstance = (instanceSet[instanceSet.length - 1] || 0) + 1;
            instanceSet.push(currentInstance);
            localStorage.setItem("instance-set", JSON.stringify(instanceSet));
            var setting1 = localStorage.getItem("".concat(currentInstance, "-setting1"));
            var setting2 = localStorage.getItem("".concat(currentInstance, "-setting2"));
        });
        // On app close remove the instance
        document.addEventListener("beforeunload", function () {
            var instanceSet = JSON.parse(localStorage.getItem("instance-set") || "[]");
            var updatedSet = instanceSet.filter(function (i) { return i !== currentInstance; });
            updatedSet.length ? localStorage.setItem("instance-set", JSON.stringify(updatedSet)) : localStorage.removeItem("instance-set");
        });
    };
    SettingsDataController.prototype.load = function () {
        return __awaiter(this, void 0, void 0, function () {
            var tempSearchBoolFlip, res, legacyStyles, links, absoluteLegacyStyles_1, urlParams;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // If versioning system exists the load it up so we can use it
                        if (localStorage.getItem('version') != null)
                            this.version = this.decodeVersion(localStorage.getItem('version'));
                        // We only want to update the versioning system on the first load
                        if (!this.loaded) {
                            // If Versioning system is missing or outdated then update it
                            if (this.version < this.decodeVersion("0.0.6")) {
                                logger.warn("[Versioning] Versioning System is missing, setting up versioning system.");
                                // Remove pre-versioning system default tasks
                                localStorage.removeItem('recent_task_0');
                                localStorage.removeItem('recent_task_count_0');
                                localStorage.removeItem('recent_task_1');
                                localStorage.removeItem('recent_task_count_1');
                                // Save the new versioning system since it wasn't setup or way outdated
                                this.save();
                            }
                            // If the versioning system is updating to 0.0.22 then we need to flip the auto-capture-search value due to updated code
                            if (this.version < this.decodeVersion("0.0.22")) {
                                logger.warn("[Versioning] Versioning System 0.0.22 flipped the auto-capture-search boolean, updating now!");
                                if (localStorage.getItem('auto-capture-search') !== null) {
                                    tempSearchBoolFlip = localStorage.getItem("auto-capture-search") === "false";
                                    localStorage.setItem("auto-capture-search", "".concat(tempSearchBoolFlip));
                                }
                                // Remove mistake on creating the ui-counter and ui-counterTimer instead of ui-overlay etc...
                                if (localStorage.getItem('ui-counter') !== null)
                                    localStorage.removeItem("ui-counter");
                                if (localStorage.getItem('ui-counter-timer') !== null)
                                    localStorage.removeItem("ui-counter-timer");
                                // No need to save as we are just updating outdated settings here
                            }
                            // If we are updating to 0.0.25 then we need to make sure the new settings are setup
                            if (this.version < this.decodeVersion("0.0.25")) {
                                logger.warn("[Versioning] Versioning System 0.0.25 new settings added, updating now!");
                                // Add these new UI settings as we have implemented them now
                                if (localStorage.getItem('ui-style') == null)
                                    localStorage.setItem("ui-style", controller.ui.style);
                                if (localStorage.getItem('ui-overlay-color') == null)
                                    localStorage.setItem("ui-overlay-color", "".concat(controller.ui.overlayColor));
                                if (localStorage.getItem('ui-overlay-timer') == null)
                                    localStorage.setItem("ui-overlay-timer", "".concat(controller.ui.overlayTimer));
                                if (localStorage.getItem('ui-history-retention-count') == null)
                                    localStorage.setItem("ui-history-retention-count", "".concat(controller.ui.historyRetentionCount));
                                // Add the new hidden creatures settings
                                if (localStorage.getItem('hidden-creatures-default') == null)
                                    localStorage.setItem("hidden-creatures-default", "".concat(controller.hiddenCreatures.default));
                                if (localStorage.getItem('hidden-creatures-dragonkin') == null)
                                    localStorage.setItem("hidden-creatures-dragonkin", "".concat(controller.hiddenCreatures.dragonkin));
                                if (localStorage.getItem('hidden-creatures-godWars') == null)
                                    localStorage.setItem("hidden-creatures-godWars", "".concat(controller.hiddenCreatures.godWars));
                                if (localStorage.getItem('hidden-creatures-ghorrock') == null)
                                    localStorage.setItem("hidden-creatures-ghorrock", "".concat(controller.hiddenCreatures.ghorrock));
                                if (localStorage.getItem('hidden-creatures-heartGielinor') == null)
                                    localStorage.setItem("hidden-creatures-heartGielinor", "".concat(controller.hiddenCreatures.heartGielinor));
                                if (localStorage.getItem('hidden-creatures-newVarrock') == null)
                                    localStorage.setItem("hidden-creatures-newVarrock", "".concat(controller.hiddenCreatures.newVarrock));
                                if (localStorage.getItem('hidden-creatures-shadowReef') == null)
                                    localStorage.setItem("hidden-creatures-shadowReef", "".concat(controller.hiddenCreatures.shadowReef));
                                if (localStorage.getItem('hidden-creatures-strongholdSecurity') == null)
                                    localStorage.setItem("hidden-creatures-strongholdSecurity", "".concat(controller.hiddenCreatures.strongholdSecurity));
                                if (localStorage.getItem('hidden-creatures-templeTrekking') == null)
                                    localStorage.setItem("hidden-creatures-templeTrekking", "".concat(controller.hiddenCreatures.templeTrekking));
                                if (localStorage.getItem('hidden-creatures-unchartedIsles') == null)
                                    localStorage.setItem("hidden-creatures-unchartedIsles", "".concat(controller.hiddenCreatures.unchartedIsles));
                                if (localStorage.getItem('hidden-creatures-elite') == null)
                                    localStorage.setItem("hidden-creatures-elite", "".concat(controller.hiddenCreatures.elite));
                                if (localStorage.getItem('hidden-creatures-monster') == null)
                                    localStorage.setItem("hidden-creatures-monster", "".concat(controller.hiddenCreatures.monster));
                                if (localStorage.getItem('hidden-creatures-summoned') == null)
                                    localStorage.setItem("hidden-creatures-summoned", "".concat(controller.hiddenCreatures.summoned));
                            }
                            // If we are updating to 0.0.25 then we need to make sure the new settings are setup
                            if (this.version < this.decodeVersion("0.0.30")) {
                                logger.warn("[Versioning] Versioning System 0.0.30 new settings added, updating now!");
                                // Add these new UI settings as we have implemented them now
                                if (localStorage.getItem('ui-style-override') == null)
                                    localStorage.setItem("ui-style-override", "".concat(controller.ui.styleOverride));
                            }
                        }
                        // Capture Settings
                        controller.autoCapture.enabled = localStorage.getItem("auto-capture-enabled") === "true";
                        controller.autoCapture.counter = localStorage.getItem("auto-capture-counter") === "true";
                        controller.autoCapture.search = localStorage.getItem("auto-capture-search") === "true";
                        controller.autoCapture.loopTimer = parseInt(localStorage.getItem("auto-capture-timer-loop"));
                        controller.autoCapture.dialogTimer = parseInt(localStorage.getItem("auto-capture-timer-dialog"));
                        controller.autoCapture.counterTimer = parseInt(localStorage.getItem("auto-capture-timer-counter"));
                        controller.autoCapture.searchTimer = parseInt(localStorage.getItem("auto-capture-timer-search"));
                        // UI Settings
                        controller.ui.styleOverride = localStorage.getItem("ui-style-override") === "true";
                        if (controller.ui.styleOverride && window.alt1 && window.alt1.skinName === "default" && localStorage.getItem('ui-style') !== "default")
                            localStorage.setItem("ui-style", "default");
                        if (controller.ui.styleOverride && window.alt1 && window.alt1.skinName === "legacy" && localStorage.getItem('ui-style') !== "legacy")
                            localStorage.setItem("ui-style", "legacy");
                        controller.ui.style = localStorage.getItem("ui-style");
                        controller.ui.hideAlt1Warn = localStorage.getItem("ui-hideAlt1Warn") === "true";
                        controller.ui.limited = localStorage.getItem("ui-limited") === "true";
                        controller.ui.status = localStorage.getItem("ui-status") === "true";
                        controller.ui.overlay = localStorage.getItem("ui-overlay") === "true";
                        controller.ui.overlayColor = localStorage.getItem("ui-overlay-color");
                        controller.ui.overlayTimer = parseInt(localStorage.getItem("ui-overlay-timer"));
                        controller.ui.historyRetentionCount = parseInt(localStorage.getItem("ui-history-retention-count"));
                        // Hidden Creatures
                        controller.hiddenCreatures.default = localStorage.getItem('hidden-creatures-default') === "true";
                        controller.hiddenCreatures.dragonkin = localStorage.getItem('hidden-creatures-dragonkin') === "true";
                        controller.hiddenCreatures.godWars = localStorage.getItem('hidden-creatures-godWars') === "true";
                        controller.hiddenCreatures.ghorrock = localStorage.getItem('hidden-creatures-ghorrock') === "true";
                        controller.hiddenCreatures.heartGielinor = localStorage.getItem('hidden-creatures-heartGielinor') === "true";
                        controller.hiddenCreatures.newVarrock = localStorage.getItem('hidden-creatures-newVarrock') === "true";
                        controller.hiddenCreatures.shadowReef = localStorage.getItem('hidden-creatures-shadowReef') === "true";
                        controller.hiddenCreatures.strongholdSecurity = localStorage.getItem('hidden-creatures-strongholdSecurity') === "true";
                        controller.hiddenCreatures.templeTrekking = localStorage.getItem('hidden-creatures-templeTrekking') === "true";
                        controller.hiddenCreatures.unchartedIsles = localStorage.getItem('hidden-creatures-unchartedIsles') === "true";
                        controller.hiddenCreatures.elite = localStorage.getItem('hidden-creatures-elite') === "true";
                        controller.hiddenCreatures.monster = localStorage.getItem('hidden-creatures-monster') === "true";
                        controller.hiddenCreatures.summoned = localStorage.getItem('hidden-creatures-summoned') === "true";
                        return [4 /*yield*/, fetch("./pages/data/version.json?cache_bust=".concat(Date.now())).then(function (r) { return r.json(); })];
                    case 1:
                        res = _a.sent();
                        if (!this.loaded && this.version < this.decodeVersion(res['version'])) {
                            this.version = this.decodeVersion(res['version']);
                            this.save();
                            window.location.href = "".concat(window.location.pathname, "?cache_bust=").concat(Date.now());
                        }
                        // TODO Load history here then reset the UI and load them
                        main.reset.single();
                        main.reset.double();
                        main.reset.reaper();
                        main.reset.suggested();
                        legacyStyles = [
                            "./styles/legacy/index.css",
                            "./styles/legacy/single.css",
                            "./styles/legacy/double.css",
                        ];
                        // Add each legacy CSS file to the <head>
                        if (controller.ui.style === "legacy") {
                            legacyStyles.forEach(function (stylePath) {
                                var linkElement = document.createElement('link');
                                linkElement.rel = 'stylesheet';
                                linkElement.href = stylePath;
                                document.head.appendChild(linkElement);
                            });
                        }
                        // Remove each legacy CSS file from the <head>
                        if (controller.ui.style === "default") {
                            links = document.querySelectorAll('head link[rel="stylesheet"]');
                            absoluteLegacyStyles_1 = legacyStyles.map(function (stylePath) {
                                return new URL(stylePath, document.baseURI).href;
                            });
                            links.forEach(function (link) {
                                var linkHref = link.href;
                                if (absoluteLegacyStyles_1.includes(linkHref))
                                    link.parentNode.removeChild(link);
                            });
                        }
                        // If we are hiding the warnings then lets apply that here
                        if (controller.ui.hideAlt1Warn)
                            dochandler.main.get.elid("warning-ui").style.visibility = "hidden";
                        urlParams = new URLSearchParams(window.location.search);
                        if (urlParams.has('bypass_warning'))
                            dochandler.main.get.elid("warning-ui").style.visibility = "hidden";
                        if (!(this.default.taskName0 != null && this.default.taskName1 != null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, main.set.double({ name: this.default.taskName0, count: this.default.taskCount0 }, { name: this.default.taskName1, count: this.default.taskCount1 })];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        if (!(this.default.taskName0 != null && this.default.taskName1 == null)) return [3 /*break*/, 5];
                        return [4 /*yield*/, main.set.single({ name: this.default.taskName0, count: this.default.taskCount0 })];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        // Settings Loaded
                        logger.log("Settings Loaded");
                        this.loaded = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    SettingsDataController.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                // Versioning System
                if (this.version > this.decodeVersion("0.0.1"))
                    localStorage.setItem('version', this.encodeVersion(this.version));
                // Capture Settings
                localStorage.setItem("auto-capture-enabled", "".concat(controller.autoCapture.enabled));
                localStorage.setItem("auto-capture-counter", "".concat(controller.autoCapture.counter));
                localStorage.setItem("auto-capture-search", "".concat(controller.autoCapture.search));
                localStorage.setItem("auto-capture-timer-loop", "".concat(controller.autoCapture.loopTimer));
                localStorage.setItem("auto-capture-timer-dialog", "".concat(controller.autoCapture.dialogTimer));
                localStorage.setItem("auto-capture-timer-counter", "".concat(controller.autoCapture.counterTimer));
                localStorage.setItem("auto-capture-timer-search", "".concat(controller.autoCapture.searchTimer));
                // UI Settings
                localStorage.setItem("ui-style", "".concat(controller.ui.style));
                localStorage.setItem("ui-style-override", "".concat(controller.ui.styleOverride));
                localStorage.setItem("ui-hideAlt1Warn", "".concat(controller.ui.hideAlt1Warn));
                localStorage.setItem("ui-limited", "".concat(controller.ui.limited));
                localStorage.setItem("ui-status", "".concat(controller.ui.status));
                localStorage.setItem("ui-overlay", "".concat(controller.ui.overlay));
                localStorage.setItem("ui-overlay-color", "".concat(controller.ui.overlayColor));
                localStorage.setItem("ui-overlay-timer", "".concat(controller.ui.overlayTimer));
                localStorage.setItem("ui-history-retention-count", "".concat(controller.ui.historyRetentionCount));
                // Hidden Creatures
                localStorage.setItem("hidden-creatures-default", "".concat(controller.hiddenCreatures.default));
                localStorage.setItem("hidden-creatures-dragonkin", "".concat(controller.hiddenCreatures.dragonkin));
                localStorage.setItem("hidden-creatures-godWars", "".concat(controller.hiddenCreatures.godWars));
                localStorage.setItem("hidden-creatures-ghorrock", "".concat(controller.hiddenCreatures.ghorrock));
                localStorage.setItem("hidden-creatures-heartGielinor", "".concat(controller.hiddenCreatures.heartGielinor));
                localStorage.setItem("hidden-creatures-newVarrock", "".concat(controller.hiddenCreatures.newVarrock));
                localStorage.setItem("hidden-creatures-shadowReef", "".concat(controller.hiddenCreatures.shadowReef));
                localStorage.setItem("hidden-creatures-strongholdSecurity", "".concat(controller.hiddenCreatures.strongholdSecurity));
                localStorage.setItem("hidden-creatures-templeTrekking", "".concat(controller.hiddenCreatures.templeTrekking));
                localStorage.setItem("hidden-creatures-unchartedIsles", "".concat(controller.hiddenCreatures.unchartedIsles));
                localStorage.setItem("hidden-creatures-elite", "".concat(controller.hiddenCreatures.elite));
                localStorage.setItem("hidden-creatures-monster", "".concat(controller.hiddenCreatures.monster));
                localStorage.setItem("hidden-creatures-summoned", "".concat(controller.hiddenCreatures.summoned));
                logger.log("Settings Saved");
                return [2 /*return*/];
            });
        });
    };
    SettingsDataController.prototype.decodeVersion = function (version) {
        var versionSplit = version.split(".");
        var versionUpper = versionSplit.length > 2 ? parseInt(versionSplit[0]) : 0;
        var versionLower = parseInt(versionSplit.length > 2 ? versionSplit[1] : versionSplit[0]);
        var versionPatch = parseInt(versionSplit.length > 2 ? versionSplit[2] : versionSplit[1]);
        return ((versionUpper * 256 * 256) + (versionLower * 256) + versionPatch);
    };
    SettingsDataController.prototype.encodeVersion = function (version) {
        var versionUpper = version > 256 * 256 ? version / (256 * 256) : 0;
        var versionLower = version > 256 ? (version - (versionUpper * 256 * 256)) / 256 : 0;
        var versionPatch = (version - (versionUpper * 256 * 256) - (versionLower * 256));
        return versionUpper + "." + versionLower + "." + versionPatch;
    };
    return SettingsDataController;
}());
export { SettingsDataController };
export var controller = new SettingsDataController();
// Change history functions
export var change;
(function (change) {
    change.history = [];
    change.index = 0;
    function addHistory(history) {
        if (!history)
            return;
        if ((history.type === "single" || history.type === "reaper") && !history.tasks && change.history.length > 0 &&
            history.tasks[0].name === change.history[change.index].tasks[0].name)
            return;
        if (history.type === "double" && !history.tasks && change.history.length > 0 &&
            history.tasks[0].name === change.history[change.index].tasks[0].name &&
            history.tasks[1].name === change.history[change.index].tasks[1].name)
            return;
        if (history.type === "suggestion" && change.history.length > 0 && history.type === change.history[change.index].type)
            return;
        if (change.index + 1 != change.history.length)
            change.history = change.history.slice(0, change.index + 1);
        logger.warn("Added History:", history);
        change.history.push(history);
        change.index = change.history.length - 1;
        pruneHistory();
        controller.save();
    }
    change.addHistory = addHistory;
    function pruneHistory() {
        if (change.history.length > controller.ui.historyRetentionCount) {
            change.history = change.history.slice(1);
            change.index = change.index - 1;
        }
    }
    function refreshCurrent() {
        if (change.index < 0 || change.index >= change.history.length)
            return;
        main.reset.single();
        main.reset.double();
        main.reset.reaper();
        main.reset.suggested();
        var currentChange = change.history[change.index];
        logger.warn("Current History:", currentChange);
        if (currentChange.type === 'double')
            main.set.double(currentChange.tasks[0], currentChange.tasks[1], true);
        if (currentChange.type === 'single')
            main.set.single(currentChange.tasks[0], true);
        if (currentChange.type === 'reaper')
            main.set.reaper(currentChange.tasks[0], true);
        if (currentChange.type === 'suggestion')
            main.set.suggested(true);
        controller.save();
    }
    change.refreshCurrent = refreshCurrent;
    function goPreviousHistory() {
        if (change.index - 1 < 0)
            return;
        var previousChange = change.history[--change.index];
        logger.warn("Previous History:", previousChange);
        if (previousChange.type === 'double')
            main.set.double(previousChange.tasks[0], previousChange.tasks[1], true);
        if (previousChange.type === 'single')
            main.set.single(previousChange.tasks[0], true);
        if (previousChange.type === 'reaper')
            main.set.reaper(previousChange.tasks[0], true);
        if (previousChange.type === 'suggestion')
            main.set.suggested(true);
        controller.save();
    }
    change.goPreviousHistory = goPreviousHistory;
    function goNextHistory() {
        if (change.index + 1 >= change.history.length)
            return;
        var nextChange = change.history[++change.index];
        logger.warn("Next History:", nextChange);
        if (nextChange.type === 'double')
            main.set.double(nextChange.tasks[0], nextChange.tasks[1], true);
        if (nextChange.type === 'single')
            main.set.single(nextChange.tasks[0], true);
        if (nextChange.type === 'reaper')
            main.set.reaper(nextChange.tasks[0], true);
        if (nextChange.type === 'suggestion')
            main.set.suggested(true);
        controller.save();
    }
    change.goNextHistory = goNextHistory;
})(change || (change = {}));
export function executeDevTools(value) {
    return __awaiter(this, void 0, void 0, function () {
        var counterData, slayerData, a, a, pos, box, _i, _a, _b, index, option, e_1;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (value === "devTools") {
                        logger.log(" ~~~ Dev Tools ~ Information Opened ~~~ ");
                        alt1.openBrowser("https://runeapps.org/forums/viewtopic.php?id=101");
                        return [2 /*return*/];
                    }
                    logger.log(" ~~~ Dev Tools ~ Started ~~~ ");
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 10, , 11]);
                    if (!(value === "size")) return [3 /*break*/, 2];
                    logger.log("Current Size: ".concat(document.body.clientWidth, ", ").concat(document.body.clientHeight));
                    return [3 /*break*/, 9];
                case 2:
                    if (!(value === "debug")) return [3 /*break*/, 3];
                    logger.setDebug(true);
                    return [3 /*break*/, 9];
                case 3:
                    if (!(value === "verbose")) return [3 /*break*/, 4];
                    logger.setVerbose(true);
                    return [3 /*break*/, 9];
                case 4:
                    if (!(value === "counterReader")) return [3 /*break*/, 6];
                    return [4 /*yield*/, slayer.counterReader.read()];
                case 5:
                    counterData = _c.sent();
                    if (counterData) {
                        logger.log("The Counter UI Collapsed [".concat(counterData.collapsed, "]"));
                        logger.log("The Counter UI Old School [".concat(counterData.OS, "]"));
                        logger.log("The Counter UI Reaper [".concat(counterData.reaper, "]"));
                        logger.log("The Counter UI Count [".concat(counterData.count, "]"));
                        logger.log("The Counter UI Task [".concat(counterData.task, "]"));
                    }
                    else {
                        logger.log(" ~~~ Dev Tools ~ Error: Cannot read counter data! ~~~ ");
                    }
                    return [3 /*break*/, 9];
                case 6:
                    if (!(value === "slayerReader")) return [3 /*break*/, 8];
                    return [4 /*yield*/, slayer.slayerReader.read()];
                case 7:
                    slayerData = _c.sent();
                    if (slayerData) {
                        logger.log("The Slayer Reader Select Your Own [".concat(slayerData.selectYourOwn, "]"));
                        logger.log("The Slayer Reader Reaper [".concat(slayerData.reaper, "]"));
                        logger.log("The Slayer Reader Ticket [".concat(slayerData.ticket, "]"));
                        for (a in slayerData.count) {
                            logger.log("The Slayer Reader Count{".concat(a, "} [").concat(slayerData.count[a], "]"));
                        }
                        for (a in slayerData.task) {
                            logger.log("The Slayer Reader Task{".concat(a, "} [").concat(slayerData.task[a], "]"));
                        }
                    }
                    else {
                        logger.log(" ~~~ Dev Tools ~ Error: Cannot read slayer data! ~~~ ");
                    }
                    return [3 /*break*/, 9];
                case 8:
                    if (value === "dialogReader") {
                        logger.log("Searching for Dialog...");
                        pos = slayer.slayerReader.dialogReader.find();
                        if (!pos) {
                            logger.log(" ~~~ Dev Tools ~ Error: Cannot find the Dialog! ~~~ ");
                            return [2 /*return*/];
                        }
                        box = slayer.slayerReader.dialogReader.read();
                        if (!box) {
                            logger.log(" ~~~ Dev Tools ~ Error: Cannot read the Dialog! ~~~ ");
                            return [2 /*return*/];
                        }
                        if (box.title)
                            logger.log("The Dialog Box Title [".concat(box.title, "]"));
                        if (box.text)
                            logger.log("The Dialog Box Text [".concat(box.text, "]"));
                        if (box.opts) {
                            logger.log("The Dialog Box Options (".concat(box.opts.length, ") [").concat(box.opts, "]"));
                            for (_i = 0, _a = box.opts.entries(); _i < _a.length; _i++) {
                                _b = _a[_i], index = _b[0], option = _b[1];
                                logger.log("\t".concat(index + 1, ". ").concat(option.text));
                            }
                        }
                    }
                    _c.label = 9;
                case 9: return [3 /*break*/, 11];
                case 10:
                    e_1 = _c.sent();
                    logger.error("~~~ Dev Tools ~ Error: Issue Capturing Screen! ~~~ ");
                    return [3 /*break*/, 11];
                case 11:
                    logger.log(" ~~~ Dev Tools ~ Finished ~~~ ");
                    return [2 /*return*/];
            }
        });
    });
}
