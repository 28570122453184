var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as a1lib from "alt1";
import * as OCR from "alt1/ocr";
import * as extrafuncs from './extrafuncs';
import * as settings from "./settings";
var imgs = a1lib.webpackImages({
    slayer_expanded_RS3: require("../images/data/slayer_expanded_RS3.data.png"),
    slayer_expanded_OSL: require("../images/data/slayer_expanded_OSL.data.png"),
    reaper_expanded_RS3: require("../images/data/reaper_expanded_RS3.data.png"),
    reaper_expanded_OSL: require("../images/data/reaper_expanded_OSL.data.png"),
    slayer_collapsed_RS3: require("../images/data/slayer_collapsed_RS3.data.png"),
    slayer_collapsed_OSL: require("../images/data/slayer_collapsed_OSL.data.png"),
    reaper_collapsed_RS3: require("../images/data/reaper_collapsed_RS3.data.png"),
    reaper_collapsed_OSL: require("../images/data/reaper_collapsed_OSL.data.png"),
});
var UICounterData = /** @class */ (function () {
    function UICounterData() {
    }
    return UICounterData;
}());
export { UICounterData };
var UICounterReader = /** @class */ (function () {
    function UICounterReader() {
        this.countFont = null;
        this.taskFont = null;
        this.data = null;
    }
    UICounterReader.prototype.read = function (buffer) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, width, height, _i, _c, match, _d, r, g, b, countText;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (!buffer)
                            buffer = a1lib.captureHoldFullRs();
                        if (!!this.countFont) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, fetch(require("../fonts/aa_10px_mono.fontmeta.json")).then(function (r) { return r.json(); })];
                    case 1:
                        _a.countFont = _e.sent();
                        _e.label = 2;
                    case 2:
                        if (!!this.taskFont) return [3 /*break*/, 4];
                        _b = this;
                        return [4 /*yield*/, fetch(require("../fonts/aa_8px_mono.fontmeta.json")).then(function (r) { return r.json(); })];
                    case 3:
                        _b.taskFont = _e.sent();
                        _e.label = 4;
                    case 4:
                        // Reset data for next search
                        this.pos = [];
                        this.data = null;
                        // Look for all UI Elements until we find one, then the rest will get skipped
                        this.findUIElement(buffer, imgs.slayer_collapsed_OSL, { reaper: false, collapsed: true, OS: true });
                        this.findUIElement(buffer, imgs.slayer_expanded_OSL, { reaper: false, collapsed: false, OS: true });
                        this.findUIElement(buffer, imgs.slayer_collapsed_RS3, { reaper: false, collapsed: true, OS: false });
                        this.findUIElement(buffer, imgs.slayer_expanded_RS3, { reaper: false, collapsed: false, OS: false });
                        this.findUIElement(buffer, imgs.reaper_collapsed_OSL, { reaper: true, collapsed: true, OS: true });
                        this.findUIElement(buffer, imgs.reaper_expanded_OSL, { reaper: true, collapsed: false, OS: true });
                        this.findUIElement(buffer, imgs.reaper_collapsed_RS3, { reaper: true, collapsed: true, OS: false });
                        this.findUIElement(buffer, imgs.reaper_expanded_RS3, { reaper: true, collapsed: false, OS: false });
                        // If we don't find any data or we found too many matches then return
                        if (!this.data && this.pos.length == 0)
                            return [2 /*return*/, null];
                        width = this.data.collapsed ? imgs.slayer_collapsed_OSL.width : imgs.slayer_expanded_OSL.width;
                        height = this.data.collapsed ? imgs.slayer_collapsed_OSL.height : imgs.slayer_expanded_OSL.height;
                        // Loop the results and return the data we find
                        for (_i = 0, _c = this.pos; _i < _c.length; _i++) {
                            match = _c[_i];
                            // Overlay a rectangle around the matched area on screen if we're running in alt1 and setting is enabled
                            if (window.alt1 && alt1.permissionOverlay && settings.controller.ui.overlay) {
                                _d = extrafuncs.hexToRgb(settings.controller.ui.overlayColor), r = _d[0], g = _d[1], b = _d[2];
                                alt1.overLayRect(a1lib.mixColor(r, g, b), match.x, match.y, width, height, settings.controller.ui.overlayTimer, 3);
                            }
                            // Get the pixel data for the matched area
                            this.data.img = buffer.toData(match.x, match.y, width, height);
                            countText = OCR.findReadLine(this.data.img, this.countFont, this.data.OS ? [[255, 152, 31]] : [[255, 203, 5]], this.data.collapsed ? 10 : 72, 15, 30, 3);
                            this.data.count = parseInt(countText.text);
                            if (!this.data.collapsed)
                                this.data.task = OCR.findReadLine(this.data.img, this.taskFont, this.data.OS ? [[255, 152, 31]] : [[255, 203, 5]], 9, 28, 156, 3).text;
                        }
                        return [2 /*return*/, (!this.pos || this.pos.length == 0) ? null : this.data];
                }
            });
        });
    };
    UICounterReader.prototype.findUIElement = function (buffer, img, mode) {
        if (!this.data && this.pos.length == 0)
            this.pos = buffer.findSubimage(img);
        if (!this.data && this.pos.length > 0)
            this.data = { reaper: mode.reaper, collapsed: mode.collapsed, OS: mode.OS, img: null, count: -1, task: null };
    };
    return UICounterReader;
}());
export { UICounterReader };
