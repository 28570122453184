// Import required modules
import * as dochandler from '../dochandler';
// Function to show the loading bar
export function loading() {
    var loadingBar = dochandler.main.get.elid("loading-bar");
    if (loadingBar) {
        loadingBar.style.visibility = "visible";
    }
}
// Function to hide the loading bar
export function loadingStop() {
    var loadingBar = dochandler.main.get.elid("loading-bar");
    if (loadingBar) {
        loadingBar.style.visibility = "hidden";
    }
}
