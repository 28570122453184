// Import required modules
import * as main from './dochandler/main';
// Export required modules
export * as animate from './dochandler/animate';
export * as change from './dochandler/change';
export * as hide from './dochandler/hide';
export * as hover from './dochandler/hover';
export * as main from './dochandler/main';
export * as show from './dochandler/show';
export * as open from './dochandler/open';
// Function to get the side element based on the index
export function getSide(i) {
    var base = main.get.elid("base");
    var side = main.get.elc(i === 0 ? "left" : "right", base);
    if (!side)
        side = main.get.elc("single", base);
    return side;
}
