var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// Import all modules as namespaces
import * as settings from '../settings';
import * as dochandler from '../dochandler';
import * as extrafuncs from '../extrafuncs';
import * as masters from '../data/masters';
import * as reapers from '../data/reapers';
import * as assignments from '../data/assignnments';
import * as creatures from '../data/creatures';
import * as variants from '../data/variants';
import * as logger from "../logger";
// Update the Creature variant shown
export function variant(i_1) {
    return __awaiter(this, arguments, void 0, function (i, creature, variant) {
        var side, data, imageContainer, imageElement, newSize, kills, slayerExp;
        if (creature === void 0) { creature = null; }
        if (variant === void 0) { variant = null; }
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    // Start loading animation
                    dochandler.animate.loading();
                    side = dochandler.getSide(i);
                    // Return early if UI is limited or data is not available
                    if ((settings.controller.ui.limited || dochandler.main.limitedLoaded) && (!creature || !variant)) {
                        dochandler.animate.loadingStop();
                        return [2 /*return*/];
                    }
                    // Fetch creature and variant from UI elements if not provided
                    if (!creature) {
                        creature = dochandler.main.get.elc("creatures", side).value;
                    }
                    if (!variant) {
                        variant = dochandler.main.get.elc("variants", side).value;
                    }
                    logger.log("Change variant to: ".concat(creature, "#").concat(variant));
                    return [4 /*yield*/, variants.get(creature, variant)];
                case 1:
                    data = _a.sent();
                    if (data) {
                        // Update UI if not limited
                        if (!settings.controller.ui.limited && !dochandler.main.limitedLoaded) {
                            imageContainer = dochandler.main.get.elc("image-container", side);
                            imageContainer.innerHTML = data["image"].replace("#", "%23");
                            imageElement = imageContainer.firstElementChild;
                            newSize = extrafuncs.calculateAspectRatioFit(parseInt(imageElement.getAttribute("width")), parseInt(imageElement.getAttribute("height")), imageContainer.offsetWidth, imageContainer.offsetHeight);
                            imageElement.style.maxWidth = "".concat(newSize.width, "px");
                            imageElement.style.maxHeight = "".concat(newSize.height, "px");
                            // Update stats
                            updateStats(side, data);
                        }
                        kills = parseInt(dochandler.main.get.elc("kills", side).innerHTML, 10);
                        slayerExp = parseFloat(data["slayer-exp"]);
                        if (!isNaN(slayerExp) && !isNaN(kills))
                            dochandler.main.get.elc("total-slayer-exp", side).innerHTML = extrafuncs.numberWithCommas((slayerExp * kills).toFixed(2));
                        else
                            dochandler.main.get.elc("total-slayer-exp", side).innerHTML = "N/A";
                    }
                    // Stop loading animation
                    dochandler.animate.loadingStop();
                    return [2 /*return*/];
            }
        });
    });
}
// Helper function to update stats
function updateStats(side, data) {
    dochandler.main.get.elc("level", side).innerHTML = data["level"];
    dochandler.main.get.elc("slayer-level", side).innerHTML = data["slayer-level"];
    dochandler.main.get.elc("lifepoints", side).innerHTML = data["lifepoints"];
    dochandler.main.get.elc("defence", side).innerHTML = data["defence"];
    dochandler.main.get.elc("max-melee", side).innerHTML = data["max-melee"];
    dochandler.main.get.elc("max-ranged", side).innerHTML = data["max-ranged"];
    dochandler.main.get.elc("max-magic", side).innerHTML = data["max-magic"];
    dochandler.main.get.elc("max-necromancy", side).innerHTML = data["max-necromancy"];
    dochandler.main.get.elc("max-spec", side).innerHTML = data["max-spec"];
    // Update weakness and susceptibility
    var weaknessElem = dochandler.main.get.elc("weaknessBox", side);
    weaknessElem.innerHTML = "<p id=\"weakness-text\">Weakness</p>" + data["weakness"].join("");
    var susceptibleElem = dochandler.main.get.elc("susceptibleBox", side);
    susceptibleElem.innerHTML = "<p id=\"susceptible-text\">Susceptible</p>" + data["susceptible"].join("");
    // Update experience values
    var experience = parseFloat(data["experience"]);
    if (!isNaN(experience))
        dochandler.main.get.elc("combat-exp", side).innerHTML = extrafuncs.numberWithCommas(experience.toFixed(2));
    else
        dochandler.main.get.elc("combat-exp", side).innerHTML = "N/A"; // Or any default value for invalid experience
    // Handle lifepoints-exp
    var lifepointsExp = parseFloat(data["lifepoint-exp"]);
    if (!isNaN(lifepointsExp))
        dochandler.main.get.elc("lifepoints-exp", side).innerHTML = extrafuncs.numberWithCommas(lifepointsExp.toFixed(2));
    else
        dochandler.main.get.elc("lifepoints-exp", side).innerHTML = "N/A"; // Or any default value for invalid lifepoints-exp
    // Handle slayer-exp
    var slayerExp = parseFloat(data["slayer-exp"]);
    if (!isNaN(slayerExp))
        dochandler.main.get.elc("slayer-exp", side).innerHTML = extrafuncs.numberWithCommas(slayerExp.toFixed(2));
    else
        dochandler.main.get.elc("slayer-exp", side).innerHTML = "N/A"; // Or any default value for invalid slayer-exp
}
// Update the Creature shown
export function creature(i_1) {
    return __awaiter(this, arguments, void 0, function (i, creature, variant) {
        var side, data, variantsElem_1;
        if (creature === void 0) { creature = null; }
        if (variant === void 0) { variant = null; }
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    // Start loading animation
                    dochandler.animate.loading();
                    side = dochandler.getSide(i);
                    // Return early if UI is limited and no creature is provided
                    if ((settings.controller.ui.limited || dochandler.main.limitedLoaded) && !creature) {
                        dochandler.animate.loadingStop();
                        return [2 /*return*/];
                    }
                    // Fetch creature from UI elements if not provided
                    if (!creature) {
                        creature = dochandler.main.get.elc("creatures", side).value;
                    }
                    logger.log("Change Creature to: ".concat(creature).concat(variant ? "#".concat(variant) : ''));
                    return [4 /*yield*/, creatures.get(creature)];
                case 1:
                    data = _a.sent();
                    if (data) {
                        // Update variants if available
                        if (data["variants"] && !settings.controller.ui.limited && !dochandler.main.limitedLoaded) {
                            variantsElem_1 = dochandler.main.get.elc("variants", side);
                            variantsElem_1.innerHTML = "";
                            data["variants"].forEach(function (a) {
                                if (!settings.controller.hiddenCreatures.templeTrekking || (settings.controller.hiddenCreatures.templeTrekking && !a["name"].startsWith("Temple Trekking")))
                                    variantsElem_1.innerHTML += "<option value=\"".concat(a["name"], "\"").concat(variant && extrafuncs.decodeHtml(a["name"]) === extrafuncs.decodeHtml(variant) ? " selected" : "", ">").concat(a["name"], "</option>");
                            });
                            if (!variant) {
                                variant = data["variants"][0]["name"];
                            }
                        }
                        // Update the variant to reflect changes
                        dochandler.change.variant(i, creature, variant);
                    }
                    // Stop loading animation
                    dochandler.animate.loadingStop();
                    return [2 /*return*/];
            }
        });
    });
}
// Update the reaper assignment data
export function reaper(task, count) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            // Ensure correct task loading conditions
            if (dochandler.main.singleLoaded || dochandler.main.doubleLoaded) {
                logger.error("Cannot load normal task as a reaper task!");
                return [2 /*return*/];
            }
            if (!dochandler.main.reaperLoaded) {
                logger.error("Cannot load reaper task without reaper being displayed!");
                return [2 /*return*/];
            }
            // Show loading animation and get side + nameElement and if not return
            dochandler.animate.loading();
            // Update and load task
            if (updateTask(2, task, count))
                return [2 /*return*/];
            loadTask(2, task, count);
            // Stop loading animation
            dochandler.animate.loadingStop();
            return [2 /*return*/];
        });
    });
}
// Update the entire Assignment data
export function assignment(i, task, count) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            // Ensure correct task loading conditions
            if (i === 0 && (!dochandler.main.singleLoaded && !dochandler.main.doubleLoaded)) {
                logger.error("Cannot load normal task if normal task isn't displayed!");
                return [2 /*return*/];
            }
            if (i === 1 && !dochandler.main.doubleLoaded) {
                logger.error("Cannot load double slayer task if only 1 is displayed!");
                return [2 /*return*/];
            }
            if (i === 2) {
                logger.error("Cannot load reaper task as a normal task!");
                return [2 /*return*/];
            }
            // Show loading animation and get side + nameElement and if not return
            dochandler.animate.loading();
            // Update and load task
            if (updateTask(i, task, count))
                return [2 /*return*/];
            loadTask(i, task, count);
            // Stop loading animation
            dochandler.animate.loadingStop();
            return [2 /*return*/];
        });
    });
}
function updateTask(i, task, count) {
    var type = "(".concat(i === 0 ? "Default" : (i === 2 ? "Reaper" : "1 Slayer Token"), ")");
    var side = dochandler.getSide(i);
    if (!side)
        return;
    var nameElement = dochandler.main.get.elc("name", side);
    if (!nameElement)
        return;
    // Check if the current task is the same as the new one
    if ((task == null && nameElement.innerHTML !== "???") || nameElement.innerHTML === task) {
        logger.log("Update task: [".concat(count, "] ").concat(task, " ").concat(type));
        dochandler.main.get.elc("kills", side).innerHTML = extrafuncs.numberWithCommas(count);
        // Get individual exp for task and update based on new count
        var slayer_exp = dochandler.main.get.elc("slayer-exp", side);
        if (slayer_exp && slayer_exp.innerHTML !== "") {
            var xp = parseInt(slayer_exp.innerHTML);
            if (!isNaN(xp))
                dochandler.main.get.elc("total-slayer-exp", side).innerHTML = extrafuncs.numberWithCommas((xp * count).toFixed(2));
            else
                dochandler.main.get.elc("total-slayer-exp", side).innerHTML = "N/A";
        }
        // TODO Update status here
        dochandler.animate.loadingStop();
        return true;
    }
    if (task == null)
        return true; // Just incase we are trying to update a task that hasn't loaded lets return and try again
    return false;
}
function loadTask(i, task, count) {
    return __awaiter(this, void 0, void 0, function () {
        var type, side, data, _a, creature_1, variant_1, cvdata, slayxp, j, img, masterData, kills, _loop_1, j, j, j, j, j, _i, _b, a, name_1, defaultCreature, defaultCreature, _c, creature_2, variant_2;
        var _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    type = "(".concat(i === 0 ? "Default" : (i === 2 ? "Reaper" : "1 Slayer Token"), ")");
                    side = dochandler.getSide(i);
                    if (!side)
                        return [2 /*return*/];
                    return [4 /*yield*/, (i === 2 ? reapers : assignments).get(task)];
                case 1:
                    data = _f.sent();
                    if (!data) {
                        dochandler.animate.loadingStop();
                        return [2 /*return*/];
                    }
                    logger.log("Load task: [".concat(count, "] ").concat(task, " ").concat(type));
                    dochandler.main.get.elc("type", side).innerHTML = type;
                    if (i === 2)
                        dochandler.main.get.elc("type", side).id = "type-reaper";
                    // Update UI elements with the new data
                    dochandler.main.get.elc("info", side).title = data["index"];
                    dochandler.main.get.elc("name", side).innerHTML = data["index"];
                    dochandler.main.get.elc("kills", side).innerHTML = extrafuncs.numberWithCommas(count);
                    if (!dochandler.main.limitedLoaded) return [3 /*break*/, 3];
                    _a = data["default-creature"].split("#"), creature_1 = _a[0], variant_1 = _a[1];
                    return [4 /*yield*/, variants.get(creature_1, variant_1)];
                case 2:
                    cvdata = _f.sent();
                    slayxp = parseFloat(cvdata["slayer-exp"]);
                    if (!isNaN(slayxp))
                        dochandler.main.get.elc("total-slayer-exp", side).innerHTML = extrafuncs.numberWithCommas((slayxp * count).toFixed(2));
                    else
                        dochandler.main.get.elc("total-slayer-exp", side).innerHTML = "N/A";
                    _f.label = 3;
                case 3:
                    if (!(!settings.controller.ui.limited && !dochandler.main.limitedLoaded)) return [3 /*break*/, 8];
                    // Update masters data
                    dochandler.main.get.elc("masters", side).innerHTML = "<div class=\"nistext masterTitle\">Assignment Range</div>";
                    j = 0;
                    _f.label = 4;
                case 4:
                    if (!(j < data["masters"].length)) return [3 /*break*/, 7];
                    img = "<img id=\"masterImage".concat(j, "\" alt=\"").concat(data["masters"][j], "\" src=\"./images/masters/").concat(data["masters"][j], ".png\" title=\"").concat(data["masters"][j], "\"></img>");
                    return [4 /*yield*/, masters.get(data["masters"][j])];
                case 5:
                    masterData = _f.sent();
                    kills = masterData["task-counts"][data["index"]];
                    dochandler.main.get.elc("masters", side).innerHTML += "<div class=\"nistext masterInfo".concat(j, "\">").concat(img, " Kills: ").concat(kills, "</div>");
                    _f.label = 6;
                case 6:
                    j++;
                    return [3 /*break*/, 4];
                case 7:
                    _loop_1 = function (j) {
                        dochandler.main.get.elc("masterInfo".concat(j), side).addEventListener('click', function () { return dochandler.open.wiki.master(i, j); });
                    };
                    for (j = 0; j < data["masters"].length; j++) {
                        _loop_1(j);
                    }
                    // Update equipment data
                    if (data["equipment"]) {
                        for (j = 0; j < 3; j++) {
                            dochandler.main.get.elc("equipInfo".concat(j + 1), side).innerHTML = j === 0 ? "None" : "";
                        }
                        for (j = 0; j < data["equipment"].length; j++) {
                            dochandler.main.get.elc("equipInfo".concat(j + 1), side).innerHTML = data["equipment"][j] || "<span style=\"color: red;\" title=\"There is no information in the database.\">Database Empty</span>";
                        }
                        dochandler.main.get.elc("equipMore", side).style.visibility = data["equipment"].length < 3 ? "hidden" : "visible";
                    }
                    else {
                        dochandler.main.get.elc("equipMore", side).style.visibility = "hidden";
                        dochandler.main.get.elc("equipInfo1", side).innerHTML = "<span style=\"color: red;\" title=\"There is no information in the database.\">Database Missing</span>";
                    }
                    // Update locations data
                    if (data["locations"]) {
                        for (j = 0; j < 3; j++) {
                            dochandler.main.get.elc("locationInfo".concat(j + 1), side).innerHTML = j === 0 ? "None" : "";
                        }
                        for (j = 0; j < data["locations"].length; j++) {
                            dochandler.main.get.elc("locationInfo".concat(j + 1), side).innerHTML = data["locations"][j] || "<span style=\"color: red;\" title=\"There is no information in the database.\">Database Empty</span>";
                        }
                        dochandler.main.get.elc("locationMore", side).style.visibility = data["locations"].length < 3 ? "hidden" : "visible";
                    }
                    else {
                        dochandler.main.get.elc("locationMore", side).style.visibility = "hidden";
                        dochandler.main.get.elc("locationInfo1", side).innerHTML = "<span style=\"color: red;\" title=\"There is no information in the database.\">Database Missing</span>";
                    }
                    // Update creatures data
                    if (data["creatures"]) {
                        dochandler.main.get.elc("creatures", side).innerHTML = "";
                        for (_i = 0, _b = data["creatures"]; _i < _b.length; _i++) {
                            a = _b[_i];
                            name_1 = extrafuncs.decodeHtml(a);
                            if (settings.controller.hiddenCreatures.dragonkin && name_1.endsWith("(Dragonkin Laboratory)"))
                                continue;
                            if (settings.controller.hiddenCreatures.godWars && name_1.endsWith("(God Wars Dungeon)"))
                                continue;
                            if (settings.controller.hiddenCreatures.ghorrock && name_1.endsWith("(Ghorrock)"))
                                continue;
                            if (settings.controller.hiddenCreatures.heartGielinor && name_1.endsWith("(Heart of Gielinor)"))
                                continue;
                            if (settings.controller.hiddenCreatures.newVarrock && name_1.endsWith("(New Varrock)"))
                                continue;
                            if (settings.controller.hiddenCreatures.shadowReef && (name_1.endsWith("(The Shadow Reef)") || name_1.startsWith("Summoned")))
                                continue;
                            if (settings.controller.hiddenCreatures.strongholdSecurity && name_1.endsWith("(Stronghold of Security)"))
                                continue;
                            if (settings.controller.hiddenCreatures.templeTrekking && name_1.endsWith("(Temple Trekking)"))
                                continue;
                            if (settings.controller.hiddenCreatures.unchartedIsles && name_1.endsWith("(Uncharted Isles)"))
                                continue;
                            if (settings.controller.hiddenCreatures.elite && name_1.endsWith("(elite)"))
                                continue;
                            if (settings.controller.hiddenCreatures.monster && name_1.endsWith("(monster)"))
                                continue;
                            if (settings.controller.hiddenCreatures.summoned && name_1.endsWith("(summoned)"))
                                continue;
                            if (settings.controller.hiddenCreatures.default && !extrafuncs.listContains(data["hidden-creatures"], a)) {
                                defaultCreature = ((_d = data["default-creature"]) === null || _d === void 0 ? void 0 : _d.split("#")[0]) || "";
                                dochandler.main.get.elc("creatures", side).innerHTML += "<option value=\"".concat(a, "\" ").concat(extrafuncs.decodeHtml(a) === extrafuncs.decodeHtml(defaultCreature) ? "selected" : "", ">").concat(a, "</option>");
                            }
                            else {
                                defaultCreature = ((_e = data["default-creature"]) === null || _e === void 0 ? void 0 : _e.split("#")[0]) || "";
                                dochandler.main.get.elc("creatures", side).innerHTML += "<option value=\"".concat(a, "\" ").concat(extrafuncs.decodeHtml(a) === extrafuncs.decodeHtml(defaultCreature) ? "selected" : "", ">").concat(a, "</option>");
                            }
                        }
                    }
                    // Set default creature and variant
                    if (data["default-creature"]) {
                        _c = data["default-creature"].split("#"), creature_2 = _c[0], variant_2 = _c[1];
                        dochandler.change.creature(i, creature_2, variant_2);
                    }
                    else {
                        dochandler.change.creature(i);
                    }
                    _f.label = 8;
                case 8:
                    // Stop loading animation
                    dochandler.animate.loadingStop();
                    return [2 /*return*/];
            }
        });
    });
}
