var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as dochandler from "./modules/dochandler";
import * as settings from "./modules/settings";
import * as slayer from "./modules/slayer";
import * as logger from "./modules/logger";
import "./index.html";
import "./appconfig.json";
/* COPYRIGHT NOTICE - DO NOT REMOVE
    2021 @ Unlishema Dragonsworn
    I have programmed this enitre app myself; however, I must give credit where credit is due.
    
    The credits have been moved into their own file @ https://slayer.unlishema.org/pages/credits.html
*/
/*
    ~~~ Slayer Assistant App ~~~
    Features coming in the near future (Also my current TODO List)

    ~ Before Release for Sure ~
    Update the about page. (Will need updated again on next release)
    Update the changelog page. (Will need updated again on next release)
    Update the credits page. (Will need updated again on next release)

    ~ Coming Next Release ~
    Add Revo Bar to Reapers (and Assignments later on)
    Redesign the Reaper UI (I have an idea already but wanna add Revo bar too now)

    Add ability to load the database through a php file instead of the json (Done, except for reapers.json)
    - Add other features to the database query php even if the app don't use them yet (Coming in the future)
    -- Example: http://slayer.unlishema.org/data/creatures.php?q=Dragon&l=10
    -- l=100 could limit results, d could have it give extra details, q could also be setup for seaching, and so on

    ~ Coming Very Soon Maybe even next Release ~
    Add in a status tracking area to keep track of xp/hr, kills/hr, and more (Needs finished)
    Adjust histories to use sessionStorage (Is this needed, It is only for navigating back and forth now)

    ~ Near future Release ~
    Add support for Ushubti

    ~ Settings Still needed for the app ~
    Settings:
    - Ability to toggle on and off Debug and Verbose mode

    - Ability to select default loading
    -- Default Task(s) on open and adjust doc based on it (Done, Partially)
    -- Load previous task(s) and adjust doc based on it

    - Ability to select defaults for everything (Later On)
    -- Select default creature per task
    -- Select default variant per creature

    - Ability to customize the Look N Feel of the Slayer Assistant UI (Style added just need color selectors later on now)
    - Ability to select the type of search algo for the app (Maybe Later On)
*/
if (window.alt1)
    alt1.identifyAppUrl("./appconfig.json");
// Initialize the JavaScript for the app
document.addEventListener("DOMContentLoaded", function () { return __awaiter(void 0, void 0, void 0, function () {
    var url, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                url = new URL(window.location.href);
                if (!url.searchParams.has("cache_bust")) {
                    url.searchParams.set("cache_bust", "".concat(Date.now()));
                    window.location.href = url.toString();
                }
                // Setup the main document and load assignment index
                return [4 /*yield*/, dochandler.main.setup.documentMain()];
            case 1:
                // Setup the main document and load assignment index
                _a.sent();
                // Run the Config Setup Process
                return [4 /*yield*/, settings.controller.load()];
            case 2:
                // Run the Config Setup Process
                _a.sent();
                // Start the capture loop at the configured interval
                setInterval(function () {
                    slayer.findTask();
                }, settings.controller.autoCapture.loopTimer);
                return [3 /*break*/, 4];
            case 3:
                error_1 = _a.sent();
                logger.error("Initialization error:", error_1);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); });
// Save settings before closing the app
document.addEventListener("beforeunload", function () { return __awaiter(void 0, void 0, void 0, function () {
    var error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, settings.controller.save()];
            case 1:
                _a.sent();
                return [3 /*break*/, 3];
            case 2:
                error_2 = _a.sent();
                logger.error("Error saving settings:", error_2);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
