// Add commas into a number if its big enough
export function numberWithCommas(n) {
    if (n == null)
        return "<span style=\"color: red;\">Empty</span>";
    if (n === -666)
        return "<span style=\"color: red;\">Error</span>";
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
// Convert hex color to RGB color
export function hexToRgb(hex) {
    hex = hex.replace('#', '');
    var r = parseInt(hex.substring(0, 2), 16);
    var g = parseInt(hex.substring(2, 4), 16);
    var b = parseInt(hex.substring(4, 6), 16);
    return [r, g, b];
}
// Check if the list contains what you want to find
export function listContains(list, find) {
    for (var _i = 0, list_1 = list; _i < list_1.length; _i++) {
        var l = list_1[_i];
        if (decodeHtml(l).toLowerCase() === decodeHtml(find).toLowerCase())
            return true;
    }
    return false;
}
// Get new width and height for image based on aspect ratio
export function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
    var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return { width: srcWidth * ratio, height: srcHeight * ratio };
}
// Decode the HTML chars that have been encoded during database downloading
export function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}
