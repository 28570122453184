var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var SearchData = /** @class */ (function () {
    function SearchData() {
        this.reaper = false;
        this.task = 'Dragons';
        this.probability = 0;
    }
    return SearchData;
}());
export { SearchData };
var SearchAlgorithm = /** @class */ (function () {
    function SearchAlgorithm() {
        this.assignmentsCache = {};
        this.reapersCache = {};
        this.data = new SearchData();
    }
    SearchAlgorithm.prototype.lookup = function (lookup_1) {
        return __awaiter(this, arguments, void 0, function (lookup, skipReaper) {
            var probabilities, reapers, assignments;
            if (skipReaper === void 0) { skipReaper = false; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        probabilities = {};
                        this.data = new SearchData();
                        if (!!skipReaper) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fetchData("reapers")];
                    case 1:
                        reapers = _a.sent();
                        this.calculateProbabilities(reapers, lookup, probabilities, true);
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.fetchData("assignments")];
                    case 3:
                        assignments = _a.sent();
                        this.calculateProbabilities(assignments, lookup, probabilities, false);
                        // Find the best match
                        return [2 /*return*/, this.findBestMatch(probabilities)];
                }
            });
        });
    };
    SearchAlgorithm.prototype.fetchData = function (type) {
        return __awaiter(this, void 0, void 0, function () {
            var cacheKey, data, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        cacheKey = type === "reapers" ? "reapersList" : "assignmentsList";
                        if (!(cacheKey in this["".concat(type, "Cache")])) return [3 /*break*/, 1];
                        data = this["".concat(type, "Cache")][cacheKey];
                        return [3 /*break*/, 4];
                    case 1: return [4 /*yield*/, fetch("./data/".concat(type, ".json"))];
                    case 2:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 3:
                        data = _a.sent();
                        this["".concat(type, "Cache")][cacheKey] = data;
                        _a.label = 4;
                    case 4: return [2 /*return*/, data];
                }
            });
        });
    };
    SearchAlgorithm.prototype.calculateProbabilities = function (data, lookup, probabilities, isReaper) {
        for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
            var a = data_1[_i];
            var indexScore = this.calculateIndexScore(a["index"], lookup);
            probabilities[a["index"]] = {
                reaper: isReaper,
                index: indexScore,
                alias: {},
                creatures: {}
            };
            for (var _a = 0, _b = a["alias"]; _a < _b.length; _a++) {
                var b = _b[_a];
                probabilities[a["index"]].alias[b] = this.calculateIndexScore(b, lookup);
            }
            for (var _c = 0, _d = a["creatures"]; _c < _d.length; _c++) {
                var b = _d[_c];
                probabilities[a["index"]].creatures[b] = this.calculateIndexScore(b, lookup);
            }
        }
    };
    // Calculate index score based on matching logic
    SearchAlgorithm.prototype.calculateIndexScore = function (index, lookup) {
        var score = 0;
        // Normalize input for comparison
        var normalizedIndex = index.toLowerCase();
        var normalizedLookup = lookup.toLowerCase();
        // Exact matches
        if (normalizedIndex === normalizedLookup) {
            score += 100; // Highest score for exact matches
        }
        else if (normalizedIndex === normalizedLookup + 's') {
            score += 90; // High score for plural matches
        }
        else if (normalizedLookup === normalizedIndex.slice(0, -1) && normalizedIndex.endsWith('s')) {
            score += 80; // High score for singular form matches
        }
        else {
            // Calculate the edit distance
            var editDist = this.editDistance(normalizedIndex, normalizedLookup);
            // Close matches within 3 characters
            if (editDist <= 3) {
                score += 40; // Boost for close matches
            }
            // Starts with lookup
            if (normalizedIndex.startsWith(normalizedLookup)) {
                score += 30; // Boost for prefix matches
            }
        }
        return score;
    };
    SearchAlgorithm.prototype.findBestMatch = function (probabilities) {
        for (var key in probabilities) {
            if (probabilities[key].index > this.data.probability) {
                this.data.task = key;
                this.data.reaper = probabilities[key].reaper;
                this.data.probability = probabilities[key].index;
            }
            this.checkAliasMatches(probabilities, key);
            this.checkCreatureMatches(probabilities, key);
        }
        return this.data;
    };
    SearchAlgorithm.prototype.checkAliasMatches = function (probabilities, key) {
        if (probabilities[key].alias) {
            for (var _i = 0, _a = Object.keys(probabilities[key].alias); _i < _a.length; _i++) {
                var aliasKey = _a[_i];
                if (probabilities[key].alias[aliasKey] > this.data.probability) {
                    this.data.task = key;
                    this.data.reaper = probabilities[key].reaper;
                    this.data.probability = probabilities[key].alias[aliasKey];
                }
            }
        }
    };
    SearchAlgorithm.prototype.checkCreatureMatches = function (probabilities, key) {
        if (probabilities[key].creatures) {
            for (var _i = 0, _a = Object.keys(probabilities[key].creatures); _i < _a.length; _i++) {
                var creatureKey = _a[_i];
                var creatureScore = probabilities[key].creatures[creatureKey];
                // Increase the visibility of creatures but keep them as a lower priority
                if (this.data.probability < 10) { // Allow creatures to show up if current probability is low
                    this.data.task = key;
                    this.data.reaper = probabilities[key].reaper;
                    this.data.probability = Math.max(this.data.probability, creatureScore + 20); // Add a base boost for creatures
                }
            }
        }
    };
    SearchAlgorithm.prototype.editDistance = function (s1, s2) {
        s1 = s1.toLowerCase();
        s2 = s2.toLowerCase();
        var costs = [];
        for (var i = 0; i <= s1.length; i++) {
            var lastValue = i;
            for (var j = 0; j <= s2.length; j++) {
                if (i === 0) {
                    costs[j] = j;
                }
                else if (j > 0) {
                    var newValue = costs[j - 1];
                    if (s1.charAt(i - 1) !== s2.charAt(j - 1)) {
                        newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
                    }
                    costs[j - 1] = lastValue;
                    lastValue = newValue;
                }
            }
            if (i > 0) {
                costs[s2.length] = lastValue;
            }
        }
        return costs[s2.length];
    };
    return SearchAlgorithm;
}());
export { SearchAlgorithm };
