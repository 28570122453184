// Private queues and settings
var debugQueue = [];
var verboseQueue = [];
var queueLimit = 50;
var debugEnabled = false;
var verboseEnabled = false;
// Set the queue limit for debug and verbose
export function setLimit(limit) {
    queueLimit = limit;
}
// Enable or disable debug logging
export function setDebug(enabled) {
    debugEnabled = enabled;
    if (enabled)
        debug("Debug logging enabled"); // Force Flush the Queue
}
// Enable or disable verbose logging
export function setVerbose(enabled) {
    verboseEnabled = enabled;
    if (enabled)
        verbose("Verbose logging enabled"); // Force Flush the Queue
}
// Public: Log to the normal queue and flush the queue
export function log() {
    var messages = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        messages[_i] = arguments[_i];
    }
    console.log.apply(console, messages);
}
// Public: Log to the warn queue and flush the queue
export function warn() {
    var messages = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        messages[_i] = arguments[_i];
    }
    console.warn.apply(console, messages);
}
// Public: Log to the error queue and flush the queue
export function error() {
    var messages = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        messages[_i] = arguments[_i];
    }
    console.error.apply(console, messages);
}
// Public: Log to the debug queue only if debug is enabled and flush the queue
export function debug() {
    var messages = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        messages[_i] = arguments[_i];
    }
    debugQueue.push(messages.join(' '));
    trimQueue(debugQueue);
    if (debugEnabled)
        flushQueue(debugQueue, "Debug");
}
// Public: Log to the verbose queue only if verbose is enabled and flush the queue
export function verbose() {
    var messages = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        messages[_i] = arguments[_i];
    }
    verboseQueue.push(messages.join(' '));
    trimQueue(verboseQueue);
    if (verboseEnabled)
        flushQueue(verboseQueue, "Verbose");
}
// Private: Trim the queue if it exceeds the limit (FIFO: remove first entry)
function trimQueue(queue) {
    if (queue.length > queueLimit)
        queue.shift();
}
// Private: Flush and output the entire queue, emptying it afterward
function flushQueue(queue, label) {
    while (queue.length > 0) {
        var message = queue.shift();
        if (label === "Debug")
            console.log("[".concat(label, "] ").concat(message));
        if (label === "Verbose")
            console.warn("[".concat(label, "] ").concat(message));
    }
}
