var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as a1lib from "alt1";
import { SearchAlgorithm } from './SearchAlgorithm';
import { SlayerDialogReader } from './SlayerDialogReader';
import { UICounterReader } from './UICounterReader';
import * as settings from './settings';
import * as docHandler from './dochandler';
import * as logger from "./logger";
// Setup the readers and search algo
export var searchAlgo = new SearchAlgorithm();
export var slayerReader = new SlayerDialogReader();
export var counterReader = new UICounterReader();
export var searchResult = false;
// Get a timer and setup vars
var timer = function (ms) { return new Promise(function (res) { return setTimeout(res, ms); }); };
var loopRunning = false;
// Check if we can capture the screen
export function canCaptureRunescape() {
    return window.alt1 && alt1.rsLinked && alt1.permissionPixel;
}
// Capture the screen
export function captureRunescape() {
    if (!canCaptureRunescape())
        return null;
    try {
        return a1lib.captureHoldFullRs();
    }
    catch (ex) {
        if (ex.message === "capturehold failed")
            logger.warn("Capturing screen failed! Is runescape open?");
    }
    return null;
}
// Find a task on the current screen
export function findTask(buffer) {
    return __awaiter(this, void 0, void 0, function () {
        var slayerData, counterData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!settings.controller.autoCapture.enabled || loopRunning || searchResult)
                        return [2 /*return*/];
                    if (!buffer)
                        buffer = captureRunescape();
                    if (!buffer)
                        return [2 /*return*/];
                    loopRunning = true;
                    docHandler.animate.loadingStop();
                    return [4 /*yield*/, slayerReader.read(buffer)];
                case 1:
                    slayerData = _a.sent();
                    if (!(slayerData && slayerData[0])) return [3 /*break*/, 11];
                    docHandler.animate.loading();
                    if (!slayerData.selectYourOwn) return [3 /*break*/, 3];
                    docHandler.main.set.suggested();
                    docHandler.animate.loadingStop();
                    return [4 /*yield*/, timer(settings.controller.autoCapture.dialogTimer)];
                case 2:
                    _a.sent();
                    loopRunning = false;
                    return [2 /*return*/];
                case 3:
                    if (!(slayerData.reaper && !slayerData.ticket)) return [3 /*break*/, 5];
                    return [4 /*yield*/, docHandler.main.set.reaper({ name: slayerData[0].task, count: slayerData[0].count })];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 9];
                case 5:
                    if (!slayerData.ticket) return [3 /*break*/, 7];
                    return [4 /*yield*/, docHandler.main.set.double({ name: slayerData[0].task, count: slayerData[0].count }, { name: slayerData[1].task, count: slayerData[1].count })];
                case 6:
                    _a.sent();
                    return [3 /*break*/, 9];
                case 7: return [4 /*yield*/, docHandler.main.set.single({ name: slayerData[0].task, count: slayerData[0].count })];
                case 8:
                    _a.sent();
                    _a.label = 9;
                case 9:
                    docHandler.animate.loadingStop();
                    return [4 /*yield*/, timer(settings.controller.autoCapture.dialogTimer)];
                case 10:
                    _a.sent();
                    loopRunning = false;
                    return [3 /*break*/, 21];
                case 11:
                    if (!settings.controller.autoCapture.counter) return [3 /*break*/, 20];
                    return [4 /*yield*/, counterReader.read(buffer)];
                case 12:
                    counterData = _a.sent();
                    if (!counterData) return [3 /*break*/, 18];
                    docHandler.animate.loading();
                    if (!(counterData.reaper && (docHandler.main.reaperLoaded || counterData.task != null))) return [3 /*break*/, 14];
                    return [4 /*yield*/, docHandler.main.set.reaper({ name: counterData.task, count: counterData.count })];
                case 13:
                    _a.sent();
                    return [3 /*break*/, 16];
                case 14:
                    if (!(!counterData.reaper && (docHandler.main.singleLoaded || counterData.task != null))) return [3 /*break*/, 16];
                    return [4 /*yield*/, docHandler.main.set.single({ name: counterData.task, count: counterData.count })];
                case 15:
                    _a.sent();
                    _a.label = 16;
                case 16:
                    docHandler.animate.loadingStop();
                    return [4 /*yield*/, timer(settings.controller.autoCapture.counterTimer)];
                case 17:
                    _a.sent();
                    loopRunning = false;
                    return [3 /*break*/, 19];
                case 18:
                    docHandler.animate.loadingStop();
                    loopRunning = false;
                    _a.label = 19;
                case 19: return [3 /*break*/, 21];
                case 20:
                    docHandler.animate.loadingStop();
                    loopRunning = false;
                    _a.label = 21;
                case 21: return [2 /*return*/];
            }
        });
    });
}
// Search for tasks
export function searchTasks(value) {
    return __awaiter(this, void 0, void 0, function () {
        var tool, lookup, tasks, _i, lookup_1, term, normalizedTerm, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!!value) return [3 /*break*/, 2];
                    return [4 /*yield*/, docHandler.main.get.searchInput()];
                case 1:
                    value = _a.sent();
                    _a.label = 2;
                case 2:
                    docHandler.main.reset.searchBox();
                    if (!(value.length == 0 && (searchResult || !settings.controller.autoCapture.enabled))) return [3 /*break*/, 4];
                    searchResult = false;
                    docHandler.main.toggle.searchText();
                    return [4 /*yield*/, findTask()];
                case 3:
                    _a.sent();
                    return [2 /*return*/];
                case 4:
                    if (value.startsWith("devTools ")) {
                        tool = value.includes(" ") ? value.substring(value.indexOf(" ") + 1) : value;
                        settings.executeDevTools(tool);
                        return [2 /*return*/];
                    }
                    else if (value.toLowerCase().includes("suggest")) {
                        docHandler.main.set.suggested();
                        return [2 /*return*/];
                    }
                    _a.label = 5;
                case 5:
                    searchResult = true;
                    docHandler.main.toggle.searchText();
                    logger.log("Searching for: " + value);
                    lookup = value.includes("|") ?
                        [value.substring(0, value.indexOf("|")), value.substring(value.indexOf("|") + 1)] :
                        [value];
                    tasks = [];
                    _i = 0, lookup_1 = lookup;
                    _a.label = 6;
                case 6:
                    if (!(_i < lookup_1.length)) return [3 /*break*/, 9];
                    term = lookup_1[_i];
                    normalizedTerm = term;
                    // Remove all whitespaces at start and end of search term just incase
                    while (normalizedTerm.startsWith(" "))
                        normalizedTerm = normalizedTerm.substring(1);
                    while (normalizedTerm.endsWith(" "))
                        normalizedTerm = normalizedTerm.substring(0, normalizedTerm.length - 2);
                    return [4 /*yield*/, searchAlgo.lookup(normalizedTerm, lookup.length > 1)];
                case 7:
                    data = _a.sent();
                    if (data) {
                        logger.log("Search for (Reaper/Slayer Assignment, Alias, or Creature): " + data.task);
                        tasks.push({ name: data.task, reaper: data.reaper });
                    }
                    _a.label = 8;
                case 8:
                    _i++;
                    return [3 /*break*/, 6];
                case 9:
                    if (!(tasks.length > 1)) return [3 /*break*/, 11];
                    return [4 /*yield*/, docHandler.main.set.double({ name: tasks[0].name, count: 1 }, { name: tasks[1].name, count: 1 })];
                case 10:
                    _a.sent();
                    return [3 /*break*/, 15];
                case 11:
                    if (!(tasks.length == 1)) return [3 /*break*/, 15];
                    if (!tasks[0].reaper) return [3 /*break*/, 13];
                    return [4 /*yield*/, docHandler.main.set.reaper({ name: tasks[0].name, count: 1 })];
                case 12:
                    _a.sent();
                    return [3 /*break*/, 15];
                case 13: return [4 /*yield*/, docHandler.main.set.single({ name: tasks[0].name, count: 1 })];
                case 14:
                    _a.sent();
                    _a.label = 15;
                case 15:
                    docHandler.animate.loadingStop();
                    if (!settings.controller.autoCapture.search) return [3 /*break*/, 17];
                    return [4 /*yield*/, timer(settings.controller.autoCapture.searchTimer)];
                case 16:
                    _a.sent();
                    searchResult = false;
                    docHandler.main.toggle.searchText();
                    _a.label = 17;
                case 17: return [2 /*return*/];
            }
        });
    });
}
