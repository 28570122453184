import * as dochandler from '../dochandler';
import * as settings from '../settings';
// Show the specific Creature's preview wiki page
export function assignmentWikiPreview(i) {
    if (settings.controller.ui.limited)
        return;
    var side = dochandler.getSide(i);
    dochandler.main.get.elc("preview-task-wiki", side).style.visibility = "visible";
}
// Show the specific Creature's preview wiki page
export function creatureWikiPreview(i) {
    if (settings.controller.ui.limited)
        return;
    var side = dochandler.getSide(i);
    dochandler.main.get.elc("preview-creature-wiki", side).style.visibility = "visible";
}
// Show more Equipment for task
export function moreEquip(i) {
    var _a;
    if (settings.controller.ui.limited)
        return;
    var side = dochandler.getSide(i);
    var equipHiddenElement = dochandler.main.get.elc("equipHidden", side);
    if ((_a = equipHiddenElement.innerHTML) === null || _a === void 0 ? void 0 : _a.trimEnd()) {
        equipHiddenElement.style.visibility = "visible";
    }
}
// Show more Locations for task
export function moreLocations(i) {
    var _a;
    if (settings.controller.ui.limited)
        return;
    var side = dochandler.getSide(i);
    var locationHiddenElement = dochandler.main.get.elc("locationHidden", side);
    if ((_a = locationHiddenElement.innerHTML) === null || _a === void 0 ? void 0 : _a.trimEnd()) {
        locationHiddenElement.style.visibility = "visible";
    }
}
